@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Mulish", sans-serif;
}

/* .navbar.scrolled {
  top: 0;
  padding-bottom: 10px;
  background: rgb(255, 255, 255);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-top: 0;
  border-left: 0;
} */
.dropdown-menu {
  width: 23rem !important;
  top: 100%;
  left: -140% !important;
  background-color: #9130c9 !important;
  color: #fff !important;
  padding: 20px 10px;
  padding-top: 13px;
}

.dropdown.others .dropdown-menu {
  width: 20rem !important;
  top: 100%;
  left: -110% !important;
}

.dropdown.crypto .dropdown-menu {
  width: 30rem !important;
  top: 100%;
  left: -85% !important;
}

.footer_menyu_sss {
  background: #fff;
  border-radius: 5px;
  bottom: 40vh;
  box-shadow: 10px 0 20px 2px rgba(0, 0, 0, 0.06);
  left: 47px;
  left: 8px;
  margin-right: 15px;
  padding: 7px 4px;
  position: fixed;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  width: 65px;
  z-index: 554;
}

.footer_menyu_sss .link_section {
  background: #fff;
  border-bottom: 1px solid #ededed;
  border-radius: 0;
  display: grid;
  padding: 5px 0;
  place-items: center;
  position: relative;
}

.footer_menyu_sss .link_section a,
.footer_menyu_sss .link_section a:hover {
  color: #9130c9 !important;
  text-decoration: none;
}

.footer_menyu_sss .link_section a {
  font-size: 24px;
  padding: 5px;
}

.footer_menyu_sss .link_section span {
  background-color: #fff;
  border-radius: 0 10px 10px 0;
  bottom: 0;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.08);
  color: #32343d;
  font-size: 14px;
  left: 0;
  opacity: 0;
  padding: 16px 33px;
  position: absolute;
  top: 2px;
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  z-index: -100;
}

.footer_menyu_sss .link_section:hover {
  background: #f3f5fd;
}

.blob {
  -webkit-animation: pulse-black 2s infinite;
  animation: pulse-black 2s infinite;
  border-radius: 60%;
  box-shadow: 0 0 0 0 #9130c9;
  padding: 1px 6px;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.footer_menyu_sss .link_section:hover span {
  border-radius: 0 10px 10px 0;
  opacity: 1;
  -webkit-transform: translateX(38%);
  transform: translateX(38%);
  visibility: visible;
}

header {
  background: #fff !important;
  border-bottom: 0 solid #fff !important;
  padding: 6px 0 !important;
  position: fixed !important;
  width: 100% !important;
  z-index: 9 !important;
}

header .navbar-toggler-icon {
  color: #000 !important;
  font-size: 28px;
}

.connectBtn {
  border: 1px solid #000 !important;
}

.navbar-brand .logo {
  width: 40px;
  margin-right: 16px;
  /* background: #fff; */
  padding: 0;
  /* border-radius: 38px; */
}

.navbar-brand {
  padding: 0;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff;
}

.nava ul li a {
  color: #fff;
  padding-left: 10px !important;
  font-weight: 500;
  text-decoration: none !important;
  font-size: 16px;
}

.nava ul li .bold {
  font-weight: 800 !important;
}

.nava ul li {
  padding-left: 5% !important;
}

.innerlist li {
  list-style: disc !important;
}

.res991 {
  display: flex !important;
}

.nava ul li a i {
  padding-right: 10px !important;
  color: rgb(252, 213, 53) !important;
}

@keyframes pulse-black {
  0% {
    box-shadow: 0 0 0 0 #9130c9;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }

  70% {
    box-shadow: 0 0 0 15px transparent;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 transparent;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

.dropdown {
  color: #ffffff9f;
  font-size: 14.4px;
}

#error span {
  color: red;
}

.menu_new_typr a {
  color: #000;
  font-size: 0.9rem;
  margin: 0px 20px;
  text-decoration: none;
}

.accordio_menu_button a {
  color: #000;
  font-size: 0.9rem;
  margin: 0px;
  text-decoration: none;
  font-weight: 600;
}

.header_menu,
.header_menu1 {
  border: none;
  display: flex;
  flex-direction: column;
}

.header_menu li a,
.header_menu1 li a {
  color: #053679;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: space-between;
  padding: 15px 20px;
  text-decoration: none !important;
  width: 100%;
}

a.header_menu .image_icon_link img {
  display: block;
  width: 40px !important;
  margin: 0 auto !important;
}

.title_header_menu {
  padding: 10px 2px;
}

.title_header_menu p {
  color: #053679;
  font-weight: 500;
  text-align: center !important;
  margin: 0;
}

a.header_menu {
  display: block;
  margin-bottom: 15px;
  overflow: hidden;
  text-decoration: none !important;
}

a.header_menu {
  display: block;
  margin-bottom: 15px;
  overflow: hidden;
  text-decoration: none !important;
}

.nav-btn {
  color: #000 !important;
  border: 1px solid #000 !important;
  border-radius: 5px !important;
  width: 160px !important;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 8px;
}

.nft_head {
  background-color: #121b24;
}

.services_head {
  background-color: #010005;
  min-height: 100vh !important;
}

.p2p_head {
  background-color: #000041;
}

.token_head {
  background-color: #fff;
}

.dex_head {
  background-color: #000;
}

.metaverse_head {
  background-color: #000000;
}

.game_head {
  background-color: #adb0bc;
}

/* .navbar {
  position: fixed;
  width: 100%;
  z-index: 10;
} */
/* 
.navbar-nav .nav-a {
  color: #ffffff9f;
  font-size: 0.90rem;
  margin-right: 20px;
  margin-top: 2.5px;
} */

.nav-btn {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;

  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
}

.hero {
  /* height: auto; */
}

.slide1 {
  height: 100vh;
  width: 100%;
  background-image: url("../Images/linear-skull.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
}

.howwallet img {
  filter: invert(43%) sepia(24%) saturate(4638%) hue-rotate(222deg)
    brightness(206%) contrast(1%);
}

.processwallet {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 10px;
  height: 220px;
  background-image: url("../Images/processwallet.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-left: 1px solid #7070ef;
  width: 100%;
}

.walletpen {
  width: 60px !important;
  height: 60px !important;
}

.processwallet h4 {
  font-weight: 800;
}

.processwallet .col-lg-2 {
  box-shadow: 9px 1px 16px -1px rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 9px 1px 16px -1px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 9px 1px 16px -1px rgba(255, 255, 255, 0.75);
}

.slide2 {
  height: 100vh;
  background-image: url("../Images/block-rom.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: relative;
  z-index: 0;
}

.hero-front {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.678);
  z-index: -5;
}

.col-lg-12 {
  padding: 0;
}

.hero-content {
  position: absolute;
  z-index: 4;
  padding: 10%;
}

.hero-content .h1tag {
  font-size: 3rem;
  /* Adjust the font size as needed */
  color: #fff;
  font-weight: 900;
}

.hero-content p {
  font-size: 16px;
  width: 60%;
  color: #fff;
  line-height: 1.75;
  margin-bottom: 3%;
}

.hero-content .button,
.defibtn,
.p2pbtn {
  color: #fff;
  font-size: 13px;
  letter-spacing: 2px;
  background-color: rgba(11, 48, 75, 0.363);
  font-weight: 700;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  width: 170px;
  text-decoration: none;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  -moz-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  box-shadow: 0px 0px 10px 1px rgb(0 149 255);
}

.slick-list {
  height: auto;
}

.slick-dots {
  bottom: 18%;
}

.slick-dots li button:before {
  font-size: 10px;
}

.slick-dots li.slick-active button:before {
  opacity: 100;
  font-size: 12px;
  color: #06b8ff;
}

.slick-dots li button:before {
  color: rgb(58, 148, 201);
}

.about-img {
  width: 100%;
  height: auto;
  background: url("../Images/about-bg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(100px);
  opacity: 1;
  -webkit-backdrop-filter: blur(11px);
  position: absolute;
  z-index: -1;
}

.about {
  padding: 8% 0%;
}

.aboutus {
  height: 100vh;
}

.about-right {
  padding-left: 8%;
}

.about-right img {
  width: 100%;
}

.about-left p {
  font-weight: 650;
}

.about-left span {
  color: #0e4ca1;
}

.about h2 {
  font-size: 2.5rem;
  font-weight: 900;
  text-align: left;
  color: #000;
  padding-top: 8%;
}

.homesecurity {
  text-align: center;
}

.whybi {
  height: 100vh;
}

.mobileapp {
  height: auto !important;
  width: 100%;
  background: #3d337c;
  padding: 60px 0px !important;
}

.mobileapp h2 {
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center !important;
  color: #fff;
}

.mobileappcontent .datas {
  border-left: 2px solid #fff !important;
  padding: 20px 10px !important;
  border-top: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;

  border-radius: 10px !important;
  -webkit-box-shadow: -5px 0px 10px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -5px 0px 10px 1px rgba(0, 0, 0, 0.75);
  box-shadow: -5px 0px 10px 1px rgba(0, 0, 0, 0.75);
}

.mobileappcontent .datas.datas1 {
  box-shadow: none !important;
}

.mobileappcontent .borderright {
  border-right: 2px solid #fff !important;
}

.mobileappcontent .datas h4 {
  font-size: 1.2rem;
  font-weight: 800;
  text-align: left !important;
  color: #fff;
}

.mobileappcontent .datas p {
  font-size: 16px !important;
  font-weight: 400;
  text-align: left !important;
  color: #fff;
  margin-top: 20px !important;
}

.whybi {
  padding: 3% 0%;
  width: 100%;
  height: 100vh;
  background: #3d337c;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.whybi h2 {
  font-size: 2.5rem;
  font-weight: 900;
  text-align: left;
  color: #fff;
  padding-top: 5%;
}

.why-left .bg-linear {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 84.98%,
    #3d337c 97%
  );
  width: 100%;
  height: 90%;
  z-index: 1;
  position: absolute;
  margin-bottom: 7%;
}

.whybi p {
  font-size: 16px;
  font-weight: 650;
  color: #edf0fd;
}

.whybi .why-card p {
  transition: all 0.4s ease-in-out;
}

.whybi .why-card {
  transition: transform 0.4s;
  transform: scale(1);
}

.whybi .why-card:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 22px 0 rgba(43, 41, 35, 0.473);
}

.whybi .why-card p:hover {
  color: rgb(255, 218, 91);
}

.why-left img {
  width: 100%;
  object-fit: cover;
  padding-left: 8%;
  margin-top: 10%;
}

.why-right {
  padding-left: 10%;
}

.why-right hr {
  width: 40%;
  margin-left: 0;
  border: 2px solid #000;
  border-radius: 2px;
}

.up img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.down img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.up p,
.down p {
  font-size: 14px;
}

.one {
  margin-bottom: 4%;
}

.one .why-card {
  margin: 2px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  padding-top: 25px;
  padding-bottom: 0;
  min-height: 132px;
  background: rgba(187, 187, 201, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.serv {
  margin: 10% auto;
  padding: 10%;
  background-image: linear-gradient(to bottom, #f9f9ff, #dce0f5 100%);
  border-radius: 15px;
  backdrop-filter: blur(11px);
  transition: all 0.3s;
  -webkit-backdrop-filter: blur(11px);
  overflow: hidden;
  z-index: 0;
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.serv-card {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  background-image: linear-gradient(
    325.44deg,
    #64d2c3 -2.11%,
    #4152ed 10.21%,
    #8a3bec 24.56%,
    rgba(138, 59, 236, 0) 42.76%
  );
  top: 0;
  left: 0;
  opacity: 0.5;
  filter: blur(10px);
  border-radius: 10%;
  min-height: 350px;
}

.serv-bgcard {
  border-radius: 15px;
  margin-bottom: 35px;
  padding: 0px 0;
  /* background: #595959; */
  position: relative;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 8%);
  min-height: 350px;
}

.serv-bgcard::before {
  content: " ";
  width: 95%;
  height: 95%;
  z-index: -3;
  position: absolute;
  top: auto;
  bottom: 1px;
  left: 1px;
  border-radius: 12px;
  background: linear-gradient(
    90deg,
    rgba(59, 8, 110, 1) 0%,
    rgba(70, 14, 120, 1) 35%,
    rgba(139, 56, 186, 0.86628154679841) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#117fce", endColorstr="#19b6e8", GradientType=1);
  background-repeat: repeat-x;
  outline: 1px solid transparent;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.serv-bgcard:hover::before {
  -webkit-transform: rotate(2deg) translateX(-5px) translateY(16px);
  -ms-transform: rotate(2deg) translateX(-5px) translateY(16px);
  transform: rotate(2deg) translateX(-5px) translateY(16px);
  z-index: 0;
}

.services {
  padding-bottom: 4%;
}

.services h2 {
  margin-top: 10%;
  font-size: 2.5rem;
  /* Adjust the font size as needed */
  color: #000;
  font-weight: 900;
  text-align: center;
}

.clientstack h2 {
  margin-top: 10%;
  font-size: 2.5rem;
  /* Adjust the font size as needed */
  color: #000;
  font-weight: 900;
  text-align: center;
}

.services .serv-p {
  font-size: 16px;
  text-align: center;
}

.serv img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  box-shadow: 0 30px 30px -20px rgba(0, 0, 0, 50%);
}

.serv-title {
  padding-top: 5%;
  font-size: 15px;
  font-weight: 900;
  text-align: center;
  z-index: 2;
  position: relative;
}

.serv-desc {
  font-size: 14px;
  font-weight: 630;
  text-align: center;
  z-index: 2;
  position: relative;
  color: #000;
}

.techstack {
  background-color: #141c2c;
  height: 100vh;
}

.clientstack {
  background-color: #3d337c;
  padding: 70px 0px;
}

.clientcard {
}

.clientcard:hover {
  background: #3d337c !important;
}

.techstacks {
  padding-top: 5%;
  margin-bottom: 10%;
  display: grid;
  place-items: center;
}

.slider {
  height: 250px;
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc(250px * 20);
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 16));
  }
}

.slide {
  border: 1px solid #8a8a8a;
  background-image: linear-gradient(to bottom, #52525244, #18191d 100%);
  border-radius: 15px;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  height: 200px;
  width: 259px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  padding-top: 70px;
  perspective: 100px;
  margin-left: 20px;
  transition: all 0.3s;
  object-fit: cover;
}

.slide p {
  color: #fff;
  margin-top: 2%;
  font-size: 16px;
  font-weight: 800;
}

.slide img {
  width: 100%;
}

.slide:hover {
  transform: translateY(-20px);
  box-shadow: 0 30px 30px -20px rgba(13, 34, 230, 0.5);
  border-color: blue;
}

.slider::before {
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.slider::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
}

.tech-h2 {
  padding-top: 10%;
  font-size: 2.5rem;
  color: #fff;
  font-weight: 900;
  text-align: center;
}

.stack p {
  margin: auto 0%;
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.form-section {
  width: 100%;
  height: 100vh;
  background: url("../Images/gradient2.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(100px);
  opacity: 1;
  -webkit-backdrop-filter: blur(11px);
  position: absolute;
  z-index: -1;
}
.ATMcard {
  background: #ff725e !important;
  border-radius: 20px !important;
  padding: 30px;
  min-height: 320px;
}
.cardsdata {
  width: 100%;
  background-image: linear-gradient(163deg, #ff2a00 0%, #ff6f5a 100%);
  border-radius: 20px;
  transition: all 0.3s;
}

.paymentfeature:hover {
  background: #000;
  border-radius: 10px;
  border: none;
  border-left: 2px solid #ff725e;
  height: 100%;  
}

.paymentfeature {
  border: 2px solid #ff725e;
  border-radius: 10px;
  background: #ff725e;
  height: 100%;
}

.paymentfeature:hover h3,
.paymentfeature:hover p {
  color: #fff !important;
}

.paymentfeature h3,
.paymentfeature p {
  color: #000 !important;
}

.Benpayment{
  border-left: 2px solid #ff725e;
}

.ATMcard:hover {
  transform: scale(0.98);
  border-radius: 20px;
}
.form-container {
  padding: 5% 0;
}

.form-top {
  padding: 5%;
}

.form input {
  color: #dce0f5;
  border: none;
  border-bottom: 2px solid #484747 !important;
  margin-top: 8%;
  width: 100%;
  background: none;
  outline-style: none;
}

.form-email input,
.form-down input {
  width: 100%;
}

.form :placeholder-shown {
  padding-left: 2%;
}

.form-img {
  background-image: url("../Images/block-lap.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px 0px 0px 15px;
}

.form-card {
  background-color: #18191d;
  border-radius: 15px;
  box-shadow: 0 8px 32px 0 rgb(23, 23, 24);
}

.form-top h3 {
  font-size: 1.8rem;
  color: #fff;
  font-weight: 700;
}

.form-top p {
  font-size: 1rem;
  color: #fff;
  font-weight: 300;
}

.form-down input {
  height: 100px;
}

.form-down input:placeholder-shown {
  padding-bottom: 20%;
}

.form button {
  color: #fff;
  font-size: 13px;
  letter-spacing: 2px;
  margin-top: 10%;
  background-color: rgba(11, 48, 75, 0.363);
  font-weight: 700;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  width: 170px;
  padding: 10px;
  font-family: "Mulish", sans-serif;
}

.dexdevelopimg {
  width: 100%;
}

/* //////////////////////////////////// footer ////////////////////////////////// */
.footer {
  background-color: #fff;
  height: aut0;
  padding: 5% 0 3% 0;
}

.bg-white {
  background-color: #fff !important;
}

.walletprocess {
  position: fixed !important;
  left: 50px !important;
}

.botbenefits {
  border: 1px solid #5479f7 !important;
  border-radius: 10px 30px 40px 50px;
}

.botfeatures {
  border-left: 3px solid #5479f7 !important;
}

.footer .logoo img {
  margin-top: 7px;
  margin-bottom: 10px;
}

.middle a {
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 5px;
  color: #535353;
  font-weight: 700;
}

.middle-row {
  margin: 0;
}

.foot {
  margin: 0;
  padding: 0;
}

.middle {
  margin-left: 10px;
  line-height: 2;
}

.footer .middle h3 {
  background: -webkit-linear-gradient(330deg, darkblue, darkorchid);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem;
  font-weight: 900;
  margin-top: 6px;
  margin-left: 10px;
  width: 20%;
}

.logoo p {
  font-size: 14px;
  padding-top: 10px;
  font-weight: 650;
}

.email {
  margin: 0;
  padding: 0;
}

.email p {
  font-size: 12px;
  margin: 1px 0;
  background: -webkit-linear-gradient(330deg, darkblue, darkorchid);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 750;
}

.email {
  padding-bottom: 10px;
  display: flex;
}

.logospace {
  padding-left: 3%;
  padding-top: 1%;
}

.last {
  margin: 0;
  padding: 0;
}

.last h3 {
  background: -webkit-linear-gradient(330deg, darkblue, darkorchid);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem;
  font-weight: 900;
}

.sub {
  display: flex;
  flex-grow: 1;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: 5px;
  border: 2px solid transparent;
}

.sub input {
  outline-style: none;
  outline: none;
  border: none;
  border-right: 0;
  min-height: 40px;
  width: 80%;
  border-radius: 5px 0px 0px 5px;
  padding-left: 20px;
  color: #290e9d;
  font-size: 14px;
}

.sub input:placeholder-shown {
  padding-left: 5%;
  font-size: 10px;
  background: -webkit-linear-gradient(darkblue, darkorchid);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #200a98d9;
}

.sub button {
  color: #fff;
  font-size: 12px;
  background-image: linear-gradient(to right, #00008b, #9932cc);
  font-weight: 700;
  border: none;
  outline-style: none;
  font-family: "Mulish", sans-serif;
  margin-right: 0;
  margin-left: auto;
}

ul {
  text-decoration: none;
}

li {
  list-style: none;
  text-decoration: none;
  font-size: 25px;
  padding-right: 5%;
}

.social-media {
  width: 100%;
  margin: 0;
}

.social-media1 li a {
  text-decoration: none;
  background: -webkit-linear-gradient(330deg, darkblue, darkorchid);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.social-media1 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0%;
}

.social-media h3 {
  background: -webkit-linear-gradient(330deg, darkblue, darkorchid);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem;
  font-weight: 900;
  text-align: center;
  margin-top: 10%;
}

.copyrights {
  align-items: center;
}

.copyrights hr {
  width: 50%;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
  border-top: 1px solid transparent;
  opacity: 1;
}

.copyrights p {
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.493);
  padding-top: 1%;
  padding-bottom: 2%;
}

.not-found-error {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(to right, #00008b, #9932cc);
  position: relative;
  z-index: 0;
  padding: 10% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error_h2 img {
  height: 242px;
}

.error_title_p {
  padding-top: 3%;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}

.error_desc_p {
  font-size: 1rem;
  font-weight: 300;
  color: #fff;
}

.not-found-error button {
  color: #fff;
  font-size: 13px;
  letter-spacing: 2px;
  margin-top: 10%;
  background-color: rgba(11, 48, 75, 0.363);
  font-weight: 700;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  width: 170px;
  padding: 10px;
  font-family: "Mulish", sans-serif;
}

.centralized {
  height: auto;
}

.centralized-cex {
  background: url("../Images/coin-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.cex-linear {
  background: linear-gradient(
    270.18deg,
    rgba(0, 0, 0, 0) 37.71%,
    rgba(0, 0, 0, 0.7) 52.46%,
    rgba(0, 0, 0, 0.9) 69%,
    #000000 93.05%
  );
  height: 100%;
}

.cex-linear .hero-content {
  z-index: 0;
  position: relative;
}

.centralized button,
.Defi .button,
.nft .button,
.p2p .button,
.dex .button,
.whatsmartcontract .button,
.cryptoex .button {
  color: #fff;
  font-size: 13px;
  letter-spacing: 2px;
  background-color: rgba(11, 48, 75, 0.363);
  font-weight: 700;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  width: 170px;
  text-decoration: none;
  padding: 10px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  -moz-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  box-shadow: 0px 0px 10px 1px rgb(0 149 255);
}

.whatis {
  background: #030303;
  padding: 0%;
}

.what-left img {
  width: 100%;
  object-fit: cover;
  padding-left: 8%;
  margin-top: 10%;
}

.what-right {
  padding-left: 10%;
}

.what p {
  font-size: 16px;
  color: #edf0fd;
}

.what h2 {
  font-size: 2.5rem;
  font-weight: 900;
  text-align: left;
  color: #fff;
  padding-top: 5%;
}

.whats {
  background-image: url("../Images/bg-space.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 10% 0;
}

.padding-class {
  padding-top: 12%;
  padding-bottom: 12%;
}

.flow-body {
  background: #fff;
  font-size: 16px;
  color: #000;
  --line-color: #5f00bc;
  padding-bottom: 8%;
}

.flow-body1 {
  background: none;
  font-size: 16px;
  color: #4f4f4f;
  --line-color: #f1a900;
  padding-bottom: 8%;
}

.title {
  font-size: 2.5rem;
  color: #000;
  text-align: center;
  font-weight: 900;
  padding-top: 7%;
  padding-bottom: 1%;
}

.title-down {
  font-size: 16px;
  color: #000;
  text-align: center;
  font-weight: 650;
  width: 85%;
  margin: 0 auto;
  padding-bottom: 7%;
}

.vertical-timeline-element-content p {
  font-weight: 650 !important;
  font-size: 14px !important;
}

.vertical-timeline-element-subtitle {
  margin-top: 5% !important;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2% !important;
}

.card-time {
  display: flex;
}

.vertical-timeline-element-content h3 {
  font-size: 4rem !important;
  font-weight: 900;
}

.vertical-timeline-element-content h5 {
  font-size: 2rem !important;
  font-weight: 700;
  margin: 0 !important;
}

.flow-body1 .vertical-timeline-element-content h5 {
  font-size: 2rem !important;
  font-weight: 700;
  margin: 0px 0px 20px 0px !important;
}

.workButton {
  background-color: #5f00bc !important;
}

.workButton:hover {
  background-color: #0ac593;
}

.vertical-timeline-element-icon {
  background-color: #5f00bc !important;
}

.flow-body1 .vertical-timeline-element-icon {
  background-color: #f1a900 !important;
}

.schoolButton {
  background-color: #5f00bc;
}

.schoolButton:hover {
  background-color: #f3bc3c;
}

.vertical-timeline--two-columns .vertical-timeline-element-content {
  transition: all 0.4s ease-in-out;
  min-height: 270px;
  display: flex;
  align-items: center;
}

.vertical-timeline--two-columns .vertical-timeline-element-content:hover {
  background-color: #5f00bc;
  color: #f4f4f4;
  cursor: pointer;
  box-shadow: 0 0.25em 1.1em 6px rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
}

.flow-body1
  .vertical-timeline--two-columns
  .vertical-timeline-element-content:hover {
  background-color: #f3bc3c;
  color: #f4f4f4;
  cursor: pointer;
  box-shadow: 0 0.25em 1.1em 6px rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
}

.vertical-timeline--two-columns .vertical-timeline-element-content-arrow:hover {
  color: #5f00bc;
}

.why-bin {
  background: #5f00bc;
  color: #fff;
}

.whybi-right img {
  width: 100%;
  object-fit: cover;
}

.whys {
  padding-top: 12%;
  padding-bottom: 12%;
}

.why-tick-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1%;
}

.why-tick-card p {
  margin: auto 0;
}

.whybi-left h2 {
  font-size: 2.5rem;
  font-weight: 900;
  width: 100%;
}

.whybi-left .down img {
  width: 30px;
  height: 30px;
}

.whybi-right {
  margin: 0;
  padding: 0;
}

.cex-cry-service {
  background: linear-gradient(176deg, #26002c 0%, rgb(0 0 0) 100%);
  padding-top: 5%;
}

.cex-cry-serv-content h2 {
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
  color: #fff;
}

.cex-cry-serv-content p {
  font-size: 16px;

  text-align: center;
  color: #fff;
}

.cex-cry-serv-card-row {
  padding-top: 5%;
  padding-bottom: 5%;
}

.cex-cry-serv-card {
  background-image: url("../Images/shied\ crypto.webp");
  background-position: bottom;
  background-size: cover;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 400px;
  cursor: pointer;
}

.serv-card-content {
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  left: 0;
  overflow: hidden;
  background: linear-gradient(rgba(19, 2, 36, 0.6), rgba(0, 0, 0, 0.5));
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.cex-cry-serv-card:hover .serv-card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cex-cry-serv-card:hover .serv-card-content-desc {
  opacity: 1;
}

.cex-cry-serv-card:hover .serv-card-content-title {
  margin: 0.5%;
}

.serv-card-content p {
  color: #fff;
}

.serv-card-content .serv-card-content-title {
  font-weight: 800;
  font-size: 1.7rem;
  transition: 0.3s ease-in-out;
}

.serv-card-content .serv-card-content-desc {
  color: #fff;
  font-weight: 650;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.servcs-1 {
  background-image: url("../Images/serv-1.webp");
  background-position: 30%;
}

.gamess-1 {
  background-image: url("../Images/cards/games8.webp");
  background-position: 30%;
}

.gamess-2 {
  background-image: url("../Images/cards/games4.webp");
  background-position: 30%;
}

.gamess-3 {
  background-image: url("../Images/cards/games3.webp");
  background-position: 30%;
}

.servcs-2 {
  background-image: url("../Images/serv-2.webp");
  background-position: bottom;
}

.servcs-3 {
  background-image: url("../Images/serv-3.webp");
}

.servcs-4 {
  background-image: url("../Images/serv-4.webp");
}

.servcs-5 {
  background-image: url("../Images/serv-5.webp");
  background-position: 28%;
}

.servcs-6 {
  background-image: url("../Images/serv-6.webp");
}

.servcs-7 {
  background-image: url("../Images/serv-7.webp");
}

.servcs-8 {
  background-image: url("../Images/serv-8.webp");
}

.servcs-9 {
  background-image: url("../Images/serv-9.webp");
}

.servcs-10 {
  background-image: url("../Images/serv-10.webp");
  background-position: 88%;
}

.last-serv {
  display: flex;
  justify-content: center;
  align-items: center;
}

#main {
  margin: 50px 0;
}

#main #faq .card {
  margin-bottom: 1px;
  border: 0;
}

#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #ffffff;
  color: #222;
  padding: 20px;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f106";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #ffffff;
  color: #000;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f107";
}

#main #faq .card .collapsing {
  background: #fff;
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #fff;
  line-height: 30px;
  color: #222;
}

.faq {
  background-image: url("../Images/bg-faq.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding: 2% 0% 5%;
}

.openfaq {
  background-color: #9e73da !important;
  padding: 70px 0px !important;
}

.walletfaq {
  background-color: #000 !important;
  padding: 70px 0px !important;
}

.openseacard {
  background-color: #a487ca !important;
  padding: 30px !important;
  border-left: 3px solid #0e1577;
  height: 325px;
}

.vscustom h5,
.vsscript h5 {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  line-height: 25px !important;
}

.vscustom {
  background-color: #a487ca !important;
  height: auto !important;
}

.vsscript {
  background: #9243fd;
  height: auto !important;
}

.rariscript h5,
.raricustom h5 {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #000 !important;
  line-height: 25px !important;
}

.rariscript {
  background: #ffb600 !important;
}

.raricustom {
  background: #ffd66e !important;
}

.border-right {
  border-right: 3px solid black !important;
}

.border-left {
  border-left: 3px solid black !important;
}

.border-top {
  border-top: 3px solid black !important;
}

.border-bottom {
  border-bottom: 3px solid black !important;
}

.rightradius {
  border-radius: 0px 40px 40px 0px;
}

.leftradius {
  border-radius: 40px 0px 0px 40px;
}

.vsscripthead {
  border-radius: 20px;
  border: 1px solid #9e72da;
  overflow: hidden;
}

.vsrariblehead {
  border-radius: 20px;
  border: 1px solid #f8b611;
  overflow: hidden;
}

.openseacard:hover {
  border-left: 3px solid #fff;
}

.openseacard h5 {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.openseacard p {
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
  color: #fff;
}

.faq-content p {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: #fff;
}

.faq-content h3 {
  font-size: 3rem;
  font-weight: 900;
  color: #fff;
}

.accordion {
  padding-top: 5%;
  width: 100%;
  margin: 0 auto;
}

.key-feature-cex {
  background: #f3f8ff;
  padding: 5% 0;
}

.hero-title1 {
  margin-bottom: 5%;
}

.key-feature-cex-content {
  color: #000;
  padding-bottom: 2%;
}

.key-feature-cex-card-row {
  padding-bottom: 2%;
}

.key-feature-cex-content h2 {
  font-size: 2.5rem;
  font-weight: 800;
}

.key-feature-cex-content p {
  width: 70%;
}

.key-feature-cex-card img {
  width: 20px;
  filter: invert(1);
}

.key-feature-cex-card {
  background-color: #4152ed;
  border-radius: 15px;
  padding: 10%;
  display: flex;
  color: #fff;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-height: 315px;
  display: flex;
  /* transform: translateZ(20px); */
  flex-direction: column;
}

.key-feature-cex-card img {
  height: 40px;
  width: fit-content;
  padding-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.key-feature-cex-card:hover .card-img {
  filter: invert(0);
}

/* .key-feature-cex-card:hover {
  transform: translateZ(20px);
  background-color: #fff;
  border: 2px solid #4152ED;
  border-radius: 10px;
  color: #000;
} */

.key-feature-cex-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.key-feature-cex-card-desc {
  font-size: 13px;
  font-weight: 500;
}

.key-feature-cex-row1 .key-feature-cex-card-title {
  font-size: 1rem;
  text-align: center;
  padding-top: 5%;
}

.key-feature-cex-row1 .key-feature-cex-card-desc {
  font-size: 12px;
}

.key-feature-cex-card1,
.key-feature-cex-card6 {
  height: 150px;
  width: 150px;
  color: white;
  background-color: #2540ac;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  transition: all 1s ease-in-out;
  position: relative;
  z-index: -2;
  box-shadow: 0 2px 22px 0 rgba(14, 14, 15, 0.432);
}

.key-feature-cex-card2,
.key-feature-cex-card7 {
  height: 180px;
  width: 180px;
  color: white;
  background-color: #e79924;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  transition: all 1s ease-in-out;
  position: relative;
  z-index: -1;
  box-shadow: 0 2px 22px 0 rgba(14, 14, 15, 0.733);
}

.key-feature-cex-card3,
.key-feature-cex-card8 {
  color: white;
  background-color: #61228f;
  border-radius: 15px;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  transition: all 1s ease-in-out;
  position: relative;
  z-index: 0;
  box-shadow: 0 2px 22px 0 rgba(14, 14, 15, 0.432);
  transition: all 1s ease-in-out;
}

.key-feature-cex-card4,
.key-feature-cex-card9 {
  height: 180px;
  width: 180px;
  color: white;
  background-color: #ce3426;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  transition: all 1s ease-in-out;
  position: relative;
  z-index: -1;
  box-shadow: 0 2px 22px 0 rgba(14, 14, 15, 0.432);
  transition: all 1s ease-in-out;
}

.key-feature-cex-card5,
.key-feature-cex-card10 {
  height: 150px;
  width: 150px;
  color: white;
  background-color: #0f6966;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 2%;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in-out;
  position: relative;
  z-index: -2;
  opacity: 1;
  box-shadow: 0 2px 22px 0 rgba(14, 14, 15, 0.432);
  transition: all 1s ease-in-out;
}

.key-feature-cex-card10 img {
  filter: invert(0) !important;
}

.key-feature-cex-row1 {
  padding: 5% 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cex-card-img img {
  width: 40px;
  filter: invert(1);
}

/* .key-feature-cex-row1:hover {
  padding-bottom: 20%;
} */

/* .key-feature-cex-row1:hover .key-feature-cex-card5 {
  transform: rotate(30deg);
  opacity: 1;
  width: 200px;
  height: 200px;
  box-shadow: 0px 12px 24px 0 rgb(14 14 15 / 64%);
  transition: all 1s ease-in-out;
  top: 158px;
  left: 65px;
} */

/* .key-feature-cex-row1:hover .key-feature-cex-card4 {
  transform: rotate(20deg);
  opacity: 1;
  width: 200px;
  height: 200px;
  box-shadow: -2px 12px 24px 0 rgb(14 14 15 / 64%);
  transition: all 1s ease-in-out;
  top: 57px;
  left: 50px;
} */

/* .key-feature-cex-row1:hover .key-feature-cex-card3 {
  transform: translateY(20px);
  opacity: 1;
  width: 200px;
  height: 200px;
  box-shadow: -2px 12px 24px 0 rgb(14 14 15 / 64%);
} */

/* .key-feature-cex-row1:hover .key-feature-cex-card2 {
  transform: rotate(-20deg);
  width: 200px;
  height: 200px;
  opacity: 1;
  box-shadow: 2px 12px 24px 0 rgb(14 14 15 / 64%);
  top: 57px;
  right: 50px;
} */

/* .key-feature-cex-row1:hover .key-feature-cex-card1 {
  transform: rotate(-30deg);
  width: 200px;
  height: 200px;
  opacity: 1;
  box-shadow: 2px 12px 24px 0 rgb(14 14 15 / 64%);
  top: 158px;
  right: 65px;
} */

/* 
.key-feature-cex-row1:hover .key-feature-cex-card6 {
  transform: rotate(30deg);
  opacity: 1;
  width: 200px;
  height: 200px;
  box-shadow: 0px 12px 24px 0 rgb(14 14 15 / 64%);
  transition: all 1s ease-in-out;
  bottom: 158px;
  right: 65px;
} */

/* .key-feature-cex-row1:hover .key-feature-cex-card7 {
  transform: rotate(20deg);
  opacity: 1;
  width: 200px;
  height: 200px;
  box-shadow: -2px 12px 24px 0 rgb(14 14 15 / 64%);
  transition: all 1s ease-in-out;
  bottom: 57px;
  right: 50px;
} */

/* .key-feature-cex-row1:hover .key-feature-cex-card8 {
  transform: translateY(-20px);
  opacity: 1;
  width: 200px;
  height: 200px;
  box-shadow: 2px 12px 24px 0 rgb(14 14 15 / 64%);
} */
/* 
.key-feature-cex-row1:hover .key-feature-cex-card9 {
  transform: rotate(-20deg);
  width: 200px;
  height: 200px;
  opacity: 1;
  box-shadow: 2px 12px 24px 0 rgb(14 14 15 / 64%);
  bottom: 57px;
  left: 50px;
} */

/* .key-feature-cex-row1:hover .key-feature-cex-card10 {
  transform: rotate(-30deg);
  width: 200px;
  height: 200px;
  opacity: 1;
  box-shadow: 2px 12px 24px 0 rgb(14 14 15 / 64%);
  bottom: 158px;
  left: 65px;
} */

.key-feature-cex1 {
  padding: 5% 0 10%;
}

/* .bottom-padding-classs:hover {
  padding-top: 20%;
  padding-bottom: 1%;
} */

.key-feature-cex-content1 {
  color: #000;
  padding-bottom: 2%;
  text-align: center;
}

.key-feature-cex-content1 h2 {
  font-size: 2.5rem;
  font-weight: 800;
}

.key-feature-cex-content1 p {
  text-align: center;
}

.feature-section-3 {
  background-color: #f4f4f4;
  padding: 7% 0;
}

.key-feature-cex-content-3 {
  padding-bottom: 5%;
}

.wallet-card {
  background: #fff;
  min-height: 367px;
  overflow: hidden;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.wallet-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 367px;
  padding: 5%;
}

.wallet-card img {
  width: 100px;
}

.wallet-card p {
  text-align: center;
  color: #000;
}

.wallet-card-front {
  background: rgb(12, 12, 12);
  height: 1%;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  transition: 0.2s ease-in-out;
}

.wallet-card:hover .hot-wallet {
  background: rgb(255, 8, 0);
}

.wallet-card:hover .warm-wallet {
  background: rgb(255, 123, 0);
}

.wallet-card:hover .wallet-card-front {
  z-index: 3;
  left: 0;
  bottom: 99%;
  height: 100%;
}

.hot-wallet-card:hover {
  transform: translateZ(20px);
  box-shadow: 0 30px 30px -20px rgb(0 0 0 / 50%);
  background-image: linear-gradient(
    to bottom,
    rgb(255 249 255),
    rgb(255, 138, 109) 100%
  );
}

.cold-wallet-card:hover {
  transform: translateZ(20px);
  box-shadow: 0 30px 30px -20px rgb(0 0 0 / 50%);
  background-image: linear-gradient(
    to bottom,
    rgb(255 249 255),
    rgb(109, 148, 255) 100%
  );
}

.warm-wallet-card:hover {
  transform: translateZ(20px);
  box-shadow: 0 30px 30px -20px rgb(0 0 0 / 50%);
  background-image: linear-gradient(
    to bottom,
    rgb(255 249 255),
    rgb(255, 175, 109) 100%
  );
}

.wallet-card-title {
  font-size: 1.8rem;
  font-weight: 750;
}

.wallet-card-content img {
  padding-bottom: 5%;
}

.wallet-card-desc {
  font-size: 1rem;
  font-weight: 450;
}

.p-tag p {
  width: 100%;
}

.benefits-cex-sec {
  padding: 10% 0;
  background: #e1c6ff;
}

.benefits-cex .benefits {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.benefits-cex-sec .bg-img-benefits img {
  width: 100%;
  object-fit: cover;
}

.benefits-cex .benefits i {
  background: -webkit-linear-gradient(330deg, darkblue, darkorchid);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
}

.benefits-cex .benefits p {
  margin: 0;
}

.key-feature-cex-card {
  margin-bottom: 10%;
}

.vertical-timeline-element-icon svg {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
  filter: invert(1);
}

.Defi {
  /* position: absolute; */
  z-index: 4;
  padding: 8% 0px;
  background-color: #141c2c;

  /* width: 100%;
  background-image: url("../Images/defibg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0; */
}

.defi-banner {
  width: 100%;
}

.welcome {
  font-size: 3rem;
  color: #fff;
  font-weight: 900;
}

.Defi p {
  font-size: 16px;
  /* width: 80%; */
  color: #fff;
  line-height: 1.75;
  margin-bottom: 3%;
}

.whatdefi {
  display: block;
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: center;
  place-content: center;
  background-color: #fff;
}

.whatdefi h2 {
  font-size: 36px;
  font-weight: 800;
  color: #3d098e;
}

.whatdefi p {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  line-height: 32px;
  text-align: justify;
  padding-top: 10px;
}

.whatimg {
  width: 70% !important;
}

.devservices {
  padding-top: 90px;
  padding-bottom: 90px;
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-content: center;
}

.devservices h2 {
  font-size: 40px;
  color: #000;
  font-weight: 800;
  text-align: center;
}

.card-title {
  font-size: 18px;
  color: #000;
  font-weight: 700 !important;
  text-align: center;
}

.hexagon-card {
  position: relative;
  background: rgb(20 28 44);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);

  padding-top: 20px;
  padding-bottom: 20px;
  transition: background-color 0.3s ease-in-out;
  transform: (90deg);
}

.card-img-tops .serimg {
  display: block;
  margin: auto;
  width: 50px !important;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  /* animation:spin 7s linear infinite; */
}

/* @-moz-keyframes spin { 
  100% { -moz-transform: rotatey(180deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotatey(180deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotatey(360deg); 
      transform:rotatey(360deg); 
  } 
} */

.card-img-tops .serimg1 {
  display: block;
  margin: auto;
  width: 50px !important;
  -webkit-animation: flip 4s linear infinite;
  -moz-animation: flip 4s linear infinite;
  /* animation:flip 4s linear infinite; */
}

/* @-moz-keyframes flip { 
  100% { -moz-transform: rotatey(180deg); } 
} 
@-webkit-keyframes flip { 
  100% { -webkit-transform: rotatey(180deg); } 
}
@keyframes flip { 
  100% { 
      -webkit-transform: rotatey(360deg); 
      transform:rotatey(360deg); 
  } 
} */

.usecases {
  display: grid;
  place-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;
  background-color: #141c2c;
}

.usecases h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  text-align: center;
}

.usecases p {
  color: #fff;
  text-align: center;
  padding: 5px;
}

.usecase-card {
  background: #1d263b;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  padding: 10px;
}

.usecase-card1 {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  padding: 10px;
}

.usecase-card:hover {
  transform: translateZ(20px);
  box-shadow: 0 4px 22px 0 rgb(121 147 183);
  background-color: #1d263b;
  color: #f4f4f4;
}

.usecase-card1:hover {
  transform: translateZ(20px);
  box-shadow: 0 4px 22px 0 rgb(121 147 183);
}

.usecard-img-tops {
  display: block;
  margin: 10px auto;
  width: 70px !important;
  background-color: #1649ff;
  border-radius: 80px;
  padding: 10px;
}

.use-title {
  font-size: 18px;
  color: #fff;
  font-weight: 700 !important;
  padding-top: 10px;
  text-align: center;
}

.usetitle P {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
}

.servicess {
  margin-top: 30px;
}

.diftable {
  padding-top: 80px;
  padding-bottom: 100px;
  z-index: 1;
  background-color: #141b2a !important;
}

/* Style for the table heading */
.difheading h2.dif {
  font-size: 30px;
  font-weight: 800;
  color: #fff;
}

/* Style for the table */
.diftable table {
  overflow: hidden;
}

.tborder {
  border: 3px solid #fff;
  overflow: hidden;
  border-radius: 10px !important;
}

/* Style for table headers */
.diftable th {
  text-align: center;
  background-color: #e7e9f147;
  color: #fff;
  padding: 20px;
  font-size: 18px;
  border-right: 3px solid #fff;
  overflow-y: hidden;
}

/* Style for table rows */
.diftable tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.02);
}

/* Style for table cells */
.diftable td {
  text-align: center;
  padding: 15px;
  color: #fff;
  font-weight: 600;
  line-height: 26px;
  font-size: 15px;
}

.firstdata {
  border-right: 3px solid #fff;
}

.secdata {
  border-right: 3px solid #fff;
}

.dif {
  font-size: 40px;
  color: #fff;
  font-weight: 800 !important;
  text-align: center;
  padding-bottom: 20px;
}

.benfits-defi {
  background-color: #fff;
  padding-top: 70px;
  padding-bottom: 70px;
}

.benfits-defi h2 {
  color: #000000;
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  padding-bottom: 20px;
}

.sectionss {
  background-color: #141c2c;
  padding-top: 100px;
  padding-bottom: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sectionss h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: 800;
  color: #fff;
}

.process-img {
  width: 100px;
  margin: 0 auto;
}

.cloud-container {
  display: inline-block;
  /* Make the container only as big as the content (the image) */
  position: relative;
  /* Position the box-shadow relative to this container */
  padding-top: 20px;
  height: 100px;
  width: 100px;
}

.cloud-container img {
  width: 60px;
  /* Make sure the image fills the container */
  display: block;
  margin: 0 auto;

  /* Remove any extra spacing around the image */
}

.cloud-container::before {
  content: "";
  /* Create a pseudo-element for the cloud shape */
  position: absolute;
  /* Position it absolutely within the container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 4px 26px 0 rgba(121, 147, 183, 0.5),
    /* Main shadow */ -10px 0 20px -10px rgba(121, 147, 183, 0.8),
    /* Left side shadow */ 10px 0 20px -10px rgba(121, 147, 183, 0.8),
    /* Right side shadow */ 0 -20px 20px -10px rgba(121, 147, 183, 0.8);
  /* Top shadow */
  border-radius: 50%;
  /* Make the container circular to create the cloud shape */
  content: "";
  /* Clear the content */
}

.processhead {
  background: linear-gradient(112deg, #b23962, #4063ff 59.98%, #61b0ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 800;
  border-bottom: 3px solid #61b0ff;
  border-radius: 2px;
  padding-bottom: 3px;
}

.processpara {
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
}

.features-defi {
  display: grid;
  place-content: center;
  padding-top: 90px;
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #141c2a;
}

.features-defi h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: 800;
  color: #fff;
}

.feature-card {
  background-color: #fff;
  border-radius: 15px;
  padding: 10%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-height: 315px;
  display: flex;
  flex-direction: column;
}

.feature-img {
  height: 65px;
  width: fit-content;
  background-color: #4152ed;
  border-radius: 50%;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}

.feature-card-title {
  color: #000;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-size: 20px;
  font-weight: 700;
  padding-top: 3px;
}

.feature-card-para {
  color: #000;
  line-height: 30px;
}

.feature-card:hover {
  transform: translateY(-20px);
  box-shadow: 0 30px 30px -20px rgba(76, 94, 255, 0.5);
}

.chose {
  width: 80% !important;
}

.Finance-Development {
  padding-bottom: 100px;
  z-index: 1;
  background-color: #fff !important;
}

/* .Finance-Development .container {


  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 15px;
  padding: 15px;
  display: grid;
  place-content: center;
  border: 5px solid #fff;
} */

.Finance-Development h2 {
  font-size: 32px;
  font-weight: 800;
  color: #000;
  text-align: center;
}

.finance-card {
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.finance-card:hover {
  transform: scale(1.03);
}

.card-img-fiance img {
  width: 100px;
  height: auto;
  /* animation: rotate 7s linear infinite; */
}

/* @keyframes rotate { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
} */
.nft {
  height: 100vh;
  width: 100%;
  background-image: url("../Images/nftbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  padding: 12% 0px;
  /* background-color: #05050f; */
}

.nft .h1tag {
  font-size: 2.7rem;
  /* Adjust the font size as needed */
  color: #fff;
  font-weight: 900;
}

.btn.nftbtn {
  color: #fff;
  font-size: 13px;
  letter-spacing: 2px;
  background-color: rgba(11, 48, 75, 0.363);
  font-weight: 700;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  width: 170px;
  text-decoration: none;
  padding: 10px 20 px;
  -webkit-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  -moz-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  box-shadow: 0px 0px 10px 1px rgb(0 149 255);
}

.btn.nftbtn:hover {
  border: 1px solid #be8f31 !important;
}

.nft p {
  font-size: 16px;
  width: 100%;
  color: #fff;
  line-height: 1.75;
  margin-bottom: 3%;
}

.Overview {
  display: block;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;
  place-content: center;
  background-color: #121b24;
}

.banner_i {
  position: relative;
}
.pposab{
  position: absolute ;
  width: 20%;
  top: 10%; 
  left: -18%;
  z-index: 0 !important;

}

.nft-banner1 {
  width: 40%;
  margin: 0 auto !important;
  position: absolute;
  /* top: 10%; */
  left: 43%;
  z-index: 100000;
}

.nft-banner2 {
  width: 60%;
  /* leftt: 15%; */
  position: absolute;
  left: 67%;
}

.nft-banner3 {
  width: 60%;
  /* position: absolute; */
  left: 0;
}

.overimg {
  width: 80%;
}

.Overview h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 5px;
}

.Overview p {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  line-height: 32px;
  text-align: justify;
}

.Marketplace {
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 1;
  background-color: #121b24 !important;
}

.Marketplace .container {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 15px;
  padding: 20px;
  display: grid;
  opacity: 0.9;
  place-content: center;
  border: 3px solid #be8f31 !important;
  background-color: #100d05 !important;
}

.ourimg {
  width: 100%;
  float: right;
}

.Marketplace h2 {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 5px;
  padding-top: 10px;
}

.Marketplace p {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  line-height: 34px;
  /* text-align: justify; */
  /* padding-top: 20px; */
}

.nftbenfit {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #121b24;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-content: center;
}

.nftbenfit h2 {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 30px;
  padding-top: 10px;
}

.ben-card {
  background: #101010;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.bencard-img-tops {
  display: block;
  margin: 10px auto;
  width: 70px !important;
  background-color: #262525;
  border-radius: 15px;
  padding: 15px;
  border: 2px solid #be8f31 !important;
}

.ben-card:hover {
  box-shadow: 0 30px 30px -20px rgba(107, 105, 5, 0.5);
  border: 3px solid #be8f31 !important;
}

.nftbenfit p {
  text-align: center;
  line-height: 30px;
  font-weight: 500;
}

.think {
  width: 70%;
}

.list {
  font-size: 18px !important;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #fff;
  padding-bottom: 20px;
  font-weight: 600;
}

.list li {
  list-style: disc;
  padding: 3px;
}

.faq2 {
  background-color: #121b24;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 2% 0% 5%;
}

.faqimg {
  width: 100%;
}

.nft-features {
  background: #121b24;
  padding-top: 5%;
}

.techstack2 {
  background-color: #05050f;
  /* height: 100vh; */
}

.nft-usecase {
  padding-top: 90px;
  padding-bottom: 90px;
  background: #121b24;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-content: center;
}

.nft-usecase h3 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  text-align: center;
}

.nftuse {
  border: 3px solid #be8f31;
  background-color: #030303;
}

.token {
  background-image: url("../Images/serv-1.webp");
}

.smart {
  background-image: url("../Images/contract.webp");
}

.multi {
  background-image: url("../Images/multiplatform.webp");
}

.liquid {
  background-image: url("../Images/liquid.webp");
}

.nft-min {
  background-image: url("../Images/Nft-mining.webp");
}

.analysis {
  background-image: url("../Images/analytics.webp");
}

.process {
  padding-top: 90px;
  padding-bottom: 90px;
  background: #121b24;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-content: center;
}

.process h3 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  text-align: center;
  padding-bottom: 10px;
}

.process .container {
  border: 4px solid #be8f31;
  background-color: #030303;
  padding: 20px;
  border-radius: 15px;
}

/* Timeline container */
.timeline-with-icons {
  list-style: none;
  padding-left: 0;
}

/* Timeline item */
.timeline-item {
  display: flex;
  align-items: flex-start;
}

/* Timeline content */
.timeline-content {
  flex: 1;
}

/* Title */
.timeline-content h5 {
  margin-top: 10px;
  color: #be8f31;
  font-weight: 700;
  font-size: 24px;
}

.nft-process-img {
  width: 80px;
  height: auto;
  background-color: #0c0e12;
  border-radius: 50%;
  margin-right: 20px;
  padding: 15px;
}

/* Description */
.text-muted {
  margin-bottom: 15px;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 20px;
  margin-right: 10px;
}

.p2p {
  height: 700px !important;
  width: 100%;
  background-image: url("../Images/p2pnead.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
}

.p2p.metaverse {
  width: 100%;
  background-image: url("../Images/metaverse.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
}

.p2p.game {
  height: auto !important;
  width: 100%;
  background-image: url("../Images/game-banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 0;
}

.cryptoex {
  height: auto !important;
  padding: 70px 0px !important;
  width: 100%;
  background: none !important;
  z-index: 0;
}

.rarible {
  height: 100vh !important;
  padding: 70px 0px !important;
  width: 100%;
  background-image: url("../Images/rariblebg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 0;
}

.cryptoex_bg {
  background: none;
}

.cryptoex_head {
  background: linear-gradient(
    100deg,
    hsla(268, 71%, 61%, 1) 0%,
    hsla(257, 66%, 45%, 1) 100%
  ) !important;
}

.rariblrfaq,
.rarible_head {
  background-color: #f8b611;
}

.rarible_head span {
  color: #fff !important;
}

.rotateimg {
  border: 2px solid #000 !important;
  border-radius: 15px !important;
}

.rotateimg img {
  rotate: -5deg !important;
}

.p2p.tokens {
  height: auto !important;
  width: 100%;
  background: #000;
  padding: 100px 0px !important;
  z-index: 0;
}

.opensea_head {
  background-color: #9e72da !important;
}

.whatsmartcontract.whatopensea {
  background-color: #9e72da !important;
}

.whatsmartcontract.wahtrarible {
  background-color: #f8b611 !important;
}

.opensea_head span {
  color: #0e1577 !important;
}

.Opensea {
  /* min-height: 100vh !important; */
  width: 100%;
  background-image: url("../Images/Openseabanner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: absolute;
  z-index: 0 !important;
}

.Opensea .h1tag {
  font-size: 3rem;
  margin: 25% 0% 0% 0% !important;
  color: #fff;
  font-weight: 900;
}

.whatmeta,
.firstmeta {
  background: #000 !important;
}

.firstmeta h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 5px;
}

.firstmeta p {
  font-size: 18px;
  color: #fff;
  padding-bottom: 5px;
  text-align: justify;
}

.firstmetaimg {
  width: 100%;
}

.signpen {
  width: 40px !important;
  height: 50px !important;
  margin-right: 10px !important;
}

.p2p .h1tag {
  font-size: 3rem;
  margin: 20% 0% 0% 0% !important;
  color: #fff;
  font-weight: 900;
}

.cryptoex .h1tag {
  font-size: 3rem;
  margin: 20% 0% 0% 0% !important;
  color: #fff !important;
  font-weight: 900;
}

.metaverse_head span {
  color: #f614ce !important;
}

.token_head span {
  color: #ffc926 !important;
}

.smartcontractaudit span {
  color: #0098f9 !important;
}

.token_head.arbitragebot span {
  color: #5479f7 !important;
}

.token_head.paymentgateway span {
  color: #ff725e !important;
}

.token_head.cryptowallet span {
  color: #7070ef !important;
}

.cryptoex_head p,
.cryptoex_head h2,
.cryptoex_head h3 {
  color: #fff !important ;
}

.p2p p,
.cryptoex_head p {
  font-size: 16px;
  width: 100%;
  color: #fff;
  line-height: 1.75;
  margin-bottom: 3%;
}

.cryptoex_head h4 {
  font-size: 22px;
  width: 100%;
  color: #fff;
  line-height: 1.75;
  margin-bottom: 3%;
  font-weight: 600;
}

.p2p-banner {
  width: 100%;
}

.whatp {
  background-color: hsl(277.94deg 64.76% 41.18%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 20px;
  display: grid;
  opacity: 0.9;
  place-content: center;
}

.whatp2p h2,
.whatmeta h2,
.firstgames h2,
.gamesfeature h2,
.gamesbenefits h2,
.whatsmartcontract h2,
.cryptoex_head h2,
.cryptoex_head h3 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 5px;
}

.whatsmartcontract h3 {
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  padding-bottom: 5px;
}

.whysmartcontract p {
  font-size: 16px !important;
  color: #fff;
  font-weight: 200 !important;
  padding-bottom: 5px;
}

.securecontract:hover {
  border: 1px solid #0098f9;
  border-radius: 20px;
}

.securecontract img {
  padding: 10px;
  margin-right: 10px;
}

.securecontract h3 {
  margin-top: 5%;
}

.whatoke h2,
.firstp2p h2,
.firstokens h2 {
  font-size: 40px;
  color: #000;
  font-weight: 800;
  padding-bottom: 5px;
}

.whatp2p p,
.whatmeta p,
.firstgames p,
.gamesfeature p,
.whatsmartcontract p {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  padding-bottom: 5px;
  text-align: justify;
}

.whatoke p,
.firstp2p p,
.firstokens p,
.gamesbenefits p {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding-bottom: 5px;
  text-align: justify;
}

.whatp2p {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background: rgb(0, 0, 65);
}

.gamesfeature,
.gamesbenefits,
.whatsmartcontract {
  display: block;
  overflow: hidden;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background: rgb(5 2 2);
}

.whatokens {
  padding-top: 150px;
  padding-bottom: 50px;

  width: 100%;
  background-image: url("../Images/Tokenbg2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: block;
  opacity: 1;
  z-index: 10px;
}

.whatgames {
  height: auto !important;
  width: 100%;
  padding-top: 360px !important;
  background-image: url("../Images/gamesession2.1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: block;
  opacity: 1;
  z-index: 10px;
  padding-bottom: 100px;
}

.gamesservices {
  height: auto !important;
  width: 100%;
  padding-top: 200px !important;
  background-image: url("../Images/gamesession3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: block;
  opacity: 1;
  z-index: 10px;
  padding-bottom: 200px;
}

.game_head span {
  color: #f1a900 !important;
}

.p2pimg {
  width: 100%;
}

.vikingimages {
  width: 100% !important;
}

.firstp2p {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background-color: #000041;
}

.firstgames {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background-color: #000000d6;
}

.firstokens {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background-color: #fff;
}

.firstmeta {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background-color: #000041;
}

.firstp2p h2,
.firstmeta h2,
.whatgames h2,
.gamesservices h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 5px;
}

.firstp2p p,
.firstmeta p {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding-bottom: 5px;
  text-align: justify;
}

.whatgames p,
.gamesservices p {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding-bottom: 5px;
}

.firstp2pimg,
.firstmetaimg,
.firstgamesimg {
  width: 100%;
}

.tokensimg {
  width: 80%;
}

.firstmetaimg2 {
  width: 50% !important;
}

.p2p-process {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #000041;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-content: center;
}

.p2p-process h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 10px;
  text-align: center;
}

.token-process {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  place-content: center;
}

.token-process img:hover {
  animation: rotate360 7s linear infinite;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.token-process h2 {
  font-size: 40px;
  color: #000;
  font-weight: 800;
  padding-bottom: 10px;
  text-align: center;
}

.menu-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #090909;
  width: 90px;
  height: 400x;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #333;
}

.menu-item {
  text-align: center;
}

.num {
  font-size: 34px;
  color: #fff;
}

.arrowimg {
  width: 30px;
  height: 30px;
}

.process-card-para {
  background-image: linear-gradient(
    45deg,
    hsl(281deg 89% 44%) 0%,
    hsl(277deg 79% 46%) 11%,
    hsl(272deg 73% 47%) 22%,
    hsl(268deg 69% 48%) 33%,
    hsl(263deg 66% 48%) 44%,
    hsl(258deg 63% 47%) 56%,
    hsl(253deg 61% 47%) 67%,
    hsl(247deg 59% 46%) 78%,
    hsl(240deg 58% 46%) 89%,
    hsl(233deg 69% 42%) 100%
  );
  padding: 25% 0px;
  margin-bottom: 15px;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 10%;
  border-top-left-radius: 10%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-height: 350px !important;
  display: FLEX;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.pro {
  font-size: 14px;
  text-align: center;
  color: #fff;
  line-height: 28px;
  padding: 0px 16px;
}

.features-p2p {
  display: grid;
  place-content: center;
  padding-top: 90px;
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #000041;
}

.features-p2p h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 10px;
  text-align: center;
}

.features-tokens,
.Benfit-tokens {
  display: grid;
  place-content: center;
  padding-top: 90px;
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.features-tokens h2,
.Benfit-tokens h2 {
  font-size: 40px;
  color: #000;
  font-weight: 800;
  padding-bottom: 10px;
  text-align: center;
}

.feature-card-p2p {
  background-color: #6f21a2;
  border-radius: 15px;
  padding: 30px 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-height: 229px;
}

.feature-card-p2p:hover {
  background-color: #2b2e42;
  transform: scale(1.05);
  color: #dbc761 !important;
}

.feature-card-tokens {
  background-color: #ffc926;
  border-radius: 15px;
  padding: 30px 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-height: 450px;
}

.feature-card-tokens:hover {
  background-color: #fec826ad;
  transform: scale(1.05);
}

.features-meta {
  padding-top: 90px;
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.features-meta h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 10px;
  text-align: center;
}

.feature-card-meta {
  background-color: #000;
  border: 1px solid #f614ce !important;
  border-radius: 55px 55px 55px 0px !important;
  padding: 30px 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-height: 400px;
  max-height: 500px;
}

.feature-card-meta:hover {
  background-color: #753394;
  border: 1px solid #753394;
  transform: scale(1.05);
  color: #dbc761 !important;
}

.p2pcontent {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.feature-img-p2p {
  width: 70px;
  margin: 0 auto;
  padding-bottom: 20px;
  filter: grayscale(100%) brightness(100);
}

.feature-card-p2ptitle {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding-top: 3px;
  text-align: center;
}

.p2p-keyfeatures {
  display: grid;
  place-content: center;
  padding-top: 90px;
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #000041;
}

.token-keyfeatures {
  display: grid;
  place-content: center;
  padding-top: 90px;
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.feature-img-meta {
  width: 70px;
  margin: 0 auto;
  padding-bottom: 20px;
  filter: grayscale(100%) brightness(100);
}

.feature-card-metatitle {
  color: #f614ce;
  font-size: 20px;
  font-weight: 700;
  padding-top: 3px;
  text-align: center;
}

.meta-keyfeatures {
  display: grid;
  place-content: center;
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #000041;
}

.meta-keyfeatures {
  display: grid;
  place-content: center;
  padding-top: 90px;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.p2p-keyfeatures h2,
.meta-keyfeatures h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 30px;
  text-align: center;
}

.token-keyfeatures h2 {
  font-size: 40px;
  color: #000;
  font-weight: 800;
  padding-bottom: 30px;
  text-align: center;
}

.keyfeature-card-meta {
  background-image: url("../Images/keybg1.webp");
  background-position: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-height: 450px;
  min-height: 300px;
  padding: 10px;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 20px;
}

.keyfeature-card-meta:hover {
  transform: scale(1.05);

  filter: contrast(1.2) brightness(1.2) sepia(1) hue-rotate(180deg)
    drop-shadow(0px 0px 10px rgba(255, 0, 0, 0.7));
  -webkit-filter: contrast(1.2) brightness(1.2) sepia(1) hue-rotate(180deg);
}

.keyfeature-card-metatitle {
  color: #f614ce;
  font-size: 20px;
  font-weight: 800;
  margin: 15px auto;
  text-align: center;
}

.keyfeature-card-para {
  font-size: 18px;
  margin: 5px 0;
  padding: 0 5px;
  text-align: center;
  color: #fff;
}

.keyfeature-card-p2p {
  background-image: url("../Images/keybg.webp");
  background-position: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-height: 230px;
  min-height: 230px;
  padding: 10px;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.keyfeature-card-p2p:hover {
  transform: scale(1.05);

  filter: contrast(1.2) brightness(1.2) sepia(1) hue-rotate(180deg)
    drop-shadow(0px 0px 10px rgba(255, 0, 0, 0.7))
    drop-shadow(0px 0px 10px rgba(0, 255, 0, 0.7))
    drop-shadow(0px 0px 10px rgba(0, 0, 255, 0.7));
  -webkit-filter: contrast(1.2) brightness(1.2) sepia(1) hue-rotate(180deg)
    drop-shadow(0px 0px 10px rgba(0, 0, 255, 0.7));
}

.keyfeature-card-p2ptitle {
  font-size: 20px;
  font-weight: 800;
  margin: 30px auto;
  text-align: center;
}

.keyfeature-card-token {
  background-image: url("../Images/tokenkeybg1.jpg.webp");
  background-position: 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-height: 230px;
  min-height: 230px;
  padding: 10px;
  width: 100%;
  height: 100%;
  color: #000;
  padding: 20px;
  border-radius: 10px;
}

.keyfeature-card-token:hover {
  transform: scale(1.05);

  filter: contrast(1.2) brightness(1.2) sepia(1) hue-rotate(180deg);
  -webkit-filter: contrast(1.2) brightness(1.2) sepia(1) hue-rotate(180deg);
}

.keyfeature-card-tokentitle {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  margin: 30px auto;
  text-align: center;
}

.keyfeature-card-para {
  font-size: 18px;
  margin: 5px 0;
  padding: 0 5px;
  text-align: center;
}

.Benfit-p2p {
  display: grid;
  place-content: center;
  padding-top: 90px;
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #000041;
}

.Benfit-p2p h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  line-height: 65px;
  text-align: center;
  padding-bottom: 20px;
}

.benfit-card-first {
  background-color: #101010;
  padding: 10px 30px 10px 30px;
}

.Benfit-card-p2psecond {
  background-color: #6f21a2;
  padding: 20px 50px 20px 50px;
  border-radius: 50px;
  margin: 20px 0;
  min-height: 282px;
}

.Benfit-card-tokensecond {
  background-color: #ffffff;
  border: 1px solid #ffc926;
  padding: 20px 50px 20px 50px;
  border-radius: 50px;
  color: #000 !important;
  margin: 20px 0;
  min-height: 282px;
}

.Benfit-card-tokensecond:hover {
  background-color: #ffc926;
  rotate: 8deg;
}

.Benfit-card-p2psecond:hover {
  background-color: #f5f5f5;
}

.Benfit-card-p2p {
  background-color: #12151e;
  border-radius: 50px;
  transition: 0.2s;
}

.benfitp2p-img {
  width: fit-content;
  margin-top: 40%;
  padding: 10px;
}

.benfit-img-p2p2 {
  width: fit-content !important;
  padding: 10px !important;
  width: 80px !important;
  margin: 0px !important;
}

.Benfit-card-p2p:hover {
  background-color: #f5f5f5;
}

.Benfit-card-p2p:hover .benfeature-card-p2ptitle {
  color: #000;
}

.Benfit-card-p2psecond:hover .benfeature-card-p2ptit {
  color: #000;
}

.Benfit-card-tokensecond:hover .benfeature-card-p2ptit {
  color: #fff;
}

.Benfit-card-tokensecond .benfeature-card-p2ptit {
  color: #000 !important;
}

.Benfit-card-p2p:hover p {
  color: #000;
}

.benfit-img-p2p {
  width: 90px;
}

.benfeature-card-p2ptitle {
  font-size: 20px;
  font-weight: 800;
  margin: 10px auto;
  color: #fff;
  padding-bottom: 10px;
}

.Benfit-card-p2p p {
  font-size: 18px;
  color: #fff;
  line-height: 30px;
  padding-bottom: 80px;
}

.Benfit-card-p2p:hover .benfitp2p-img img {
  filter: grayscale(100%) brightness(0);
}

.benfeature-card-p2ptit {
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  padding-bottom: 10px;
}

.benfeature-card-p {
  font-size: 19px;
  color: #fff;
  line-height: 30px;
}

.Benfit-card-p2psecond:hover .benfit-img-p2p2 {
  filter: grayscale(100%) brightness(0);
}

.Benfit-card-p2psecond:hover p {
  color: #000;
}

.Benfit-card-tokensecond .benfit-img-p2p2 {
  filter: grayscale(100%) brightness(0) !important;
}

.Benfit-card-tokensecond p {
  color: #000;
}

.how-p2p {
  display: grid;
  place-content: center;
  padding-top: 90px;
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #000041;
}

.how-p2p .container {
  background-color: #fff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 20px;
  display: grid;
  place-content: center;
  border: 3px solid #6f21a2 !important;
}

.how-p2p h2 {
  font-size: 40px;
  color: #6f21a2;
  font-weight: 800;
  text-align: center;
}

.how-meta {
  padding-bottom: 90px;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.how-meta .container {
  border-radius: 20px;
  padding: 20px;
  display: grid;
  place-content: center;
}

.how-meta h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 800;
  text-align: center;
}

.keyfeature {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}

.keyfeature-image {
  flex: 0 0 auto;
  margin-right: 10px;
}

.round-image {
  width: 60px !important;
}

.title2 {
  margin-left: 10px;
  color: #6f21a2;
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
}

.how-content {
  font-size: 20px;
  color: #6f21a2;
  font-weight: 600;
  margin: 20px;
}

.stacz {
  background-color: #060817 !important;
}

.grid-container-fluid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px 20px;
  grid-auto-flow: row;
  grid-template-areas: ". . . . .";
}

.proeven {
  margin-top: 5% !important;
}

.pblock {
  color: #fff;
  text-align: center;
  padding: 20px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
}

.proodd {
  position: relative;
}

.proeven {
  position: relative;
}

.procen {
  margin-bottom: 60%;
}

.p2p-process h5 {
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 20px;
  font-weight: 800;
  line-height: 24px;
}

.process-img-p2p {
  width: 50px;
  filter: invert(99%) sepia(5%) saturate(1%) hue-rotate(294deg) brightness(104%)
    contrast(100%);
}

.contract {
  margin-bottom: 70%;
}

.rounded-number {
  background-image: linear-gradient(
    45deg,
    hsl(281deg 89% 44%) 0%,
    hsl(277deg 79% 46%) 11%,
    hsl(272deg 73% 47%) 22%,
    hsl(268deg 69% 48%) 33%,
    hsl(263deg 66% 48%) 44%,
    hsl(258deg 63% 47%) 56%,
    hsl(253deg 61% 47%) 67%,
    hsl(247deg 59% 46%) 78%,
    hsl(240deg 58% 46%) 89%,
    hsl(233deg 69% 42%) 100%
  );
  color: #fff;
  border-radius: 60%;
  /* Make it rounded */
  width: 50px;
  height: 50px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: -80px !important;
  margin: auto;
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fadeUp 1s ease forwards;
}

.proodd::before {
  position: absolute;
  content: "";
  width: 300px;
  height: 100px;
  background-image: url("../Images/arrow-dashed.webp");
  background-size: contain;
  background-repeat: no-repeat;
  top: 80px;
  right: -260px;
  filter: invert(99%) sepia(5%) saturate(1%) hue-rotate(294deg) brightness(104%)
    contrast(100%);
  transform: rotate(45deg);
}

.proeven::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 117px;
  background-image: url("../Images/arrow-dashed2.webp");
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 30%;
  right: -90%;
  filter: invert(99%) sepia(5%) saturate(1%) hue-rotate(294deg) brightness(104%)
    contrast(100%);
}

.dex {
  height: 100vh;
  width: 100%;
  background-image: url("../Images/dexbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
}

.dex .h1tag,
.cryptoex .h1tag {
  font-size: 2.7rem;
  /* Adjust the font size as needed */
  color: #fff;
  text-align: left;
  font-weight: 900;
  margin-top: 30%;
}

.dex p {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.75;
  text-align: left;
}

.dexbtn {
  color: #fff;
  font-size: 13px;
  letter-spacing: 2px;
  background-color: rgba(11, 48, 75, 0.363);
  font-weight: 700;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  width: 170px;
  text-decoration: none;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  -moz-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  box-shadow: 0px 0px 10px 1px rgb(0 149 255);
}

.sec2dex {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background-color: #010101;
}

.dex2 {
  padding: 50px;
  border: 3px dashed #2ee8ff;
  border-radius: 20px;
}

.deximg {
  width: 90%;
}

.p2pcontent {
  flex: 1;
  text-align: left;
  color: #fff;
  font-weight: 600;
  line-height: 34px;
}

.sec2dex h2 {
  font-size: 40px;
  color: #2ee8ff;
  font-weight: 800;
}

.p2pimg {
  max-width: 100%;
  height: auto;
}

.sec3deximg {
  width: 80%;
  height: auto;
  margin-top: 10%;
}

.whydeximg {
  max-width: 90%;
  height: auto;
}

.sec3dex {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background-color: #010101;
}

.sec3dex h2 {
  font-size: 36px;
  color: #2ee8ff;
  font-weight: 800;
}

.sec3dex p {
  flex: 1;
  /* text-align: justify; */
  color: #fff;
  font-weight: 600;
  line-height: 34px;
}

.sec4dex {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background-color: #010101;
}

.sec4dex h2 {
  font-size: 36px;
  color: #2ee8ff;
  font-weight: 800;
  text-align: center;
}

.dexf {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-card-dex {
  padding: 20px;
  border: 3px solid #2ee8ff;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(0.2px);
  -webkit-backdrop-filter: blur(0.2px);
  width: 100%;
  max-width: 350px;
  min-height: 313px;
  margin: 10px;
  border-right: #010101;
}

.feature-card-dex:hover {
  transform: rotate(8deg);
  box-shadow: 0 4px 30px rgba(46, 232, 255, 0.8);
}

.feature-img-dex {
  width: 70px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 3px;
}

.feature-card-dextitle {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding: 4px 5px;
}

.dexfeature-card-para {
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  padding: 0px 5px;
  line-height: 29px;
  text-align: left;
}

.faq3 {
  background-color: #010101;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 2% 0% 5%;
}

.faq3 h3 {
  font-size: 36px;
  color: #2ee8ff;
  font-weight: 800;
}

.frequent {
  background-color: #2ee8ff !important;
}

.sec5dex {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background-color: #010101;
}

.sec5dex h2 {
  font-size: 36px;
  color: #2ee8ff;
  font-weight: 800;
  text-align: center;
}

.bendexf {
  display: flex;
  justify-content: center;
  align-items: center;
}

.benfit-img-dex {
  border: 3px solid #2ee8ff;
  filter: brightness(0);
  width: 60px;
  margin: 0 auto;
  border-radius: 50px;
  padding: 3px;
}

.benfit-card-dex {
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(0.2px);
  -webkit-backdrop-filter: blur(0.2px);
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: #fff !important;
  min-height: 350px;
}

.benfit-card-dextitle {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  padding: 20px 5px;
}

.dexbenfit-card-para {
  font-size: 18px;
  color: #000;
  margin-top: 10px;
  padding: 4px 5px;
  line-height: 30px;
  text-align: center;
}

.sec6dex {
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  place-content: center;
  background-color: #010101;
}

.sec6dex h3 {
  font-size: 36px;
  color: #2ee8ff;
  font-weight: 800;
  text-align: center;
  padding-bottom: 40px;
}

.difdexf {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dif-card-dex {
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(14, 179, 255, 0.8);
  backdrop-filter: blur(0.2px);
  -webkit-backdrop-filter: blur(0.2px);
  border: 1px solid #2ee8ff;
  transition: all 0.3s ease-in-out;
}

.dif-card-dex2 {
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(132, 8, 228, 0.8);
  backdrop-filter: blur(0.2px);
  -webkit-backdrop-filter: blur(0.2px);
  border: 1px solid #8a3bec;
  transition: all 0.3s ease-in-out;
}

.vsdeximg {
  width: 50%;
}

.dif-card-dextitle {
  font-size: 20px;
  font-weight: 700;
  color: #2ee8ff;
  padding: 0px 5px;
}

.dif-card-para {
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  padding: 4px 5px;
  line-height: 30px;
}

.dif-card-dextitle1 {
  font-size: 20px;
  font-weight: 700;
  color: #8a3bec;
  padding: 0px 5px;
}

.dif-card-para1 {
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  padding: 4px 5px;
  line-height: 30px;
}

.faqbox {
  padding: 20px;
  /* border: 3px solid #2ee8ff; */
  border-radius: 20px;
}

.p2pcol {
  display: none !important;
}
.cryptocards {
  margin: 20px 0px !important;
  border-radius: 10px !important;
  padding: 15px 0px !important;
  min-height: 400px !important;
  background: #fff;
  background: #312f30;
}

.cryptocards:hover {
  background: none !important;
  border: 1px solid #311863 !important ;
}
.cryptocards:hover img {
  filter: brightness(0.5) !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .p2p,
  .dex {
    background-position: center !important;
  }

  .hero {
    height: auto;
  }

  .home-new .slide1 {
    width: 100%;
    background-position: center;
  }

  .home-new .slide2 {
    width: 100%;
  }

  .hero-content {
    padding: 10% 0% 0% 5%;
  }

  .slide2 .hero-content {
    padding: 12% 0% 0% 5%;
  }

  .slide2 {
    background-position: 70%;
  }

  .hero-content .h1tag,
  .cryptoex .h1tag {
    font-size: 2.2rem;
    margin-top: 5%;
  }

  .hero-content p {
    font-size: 12px;
  }

  .hero-content button {
    color: #fff;
    font-size: 12px;
    letter-spacing: 2px;
    background-color: rgba(11, 48, 75, 0.363);
    font-weight: 650;
    border: 1px solid white;
    border-radius: 5px;
    height: 35px;
    width: 129px;
    padding: 0;

    font-family: "Mulish", sans-serif;
    box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  }

  .aboutus {
    height: auto;
  }

  .about {
    padding: 12% 0%;
  }

  .about h2 {
    font-size: 1.8rem;
    padding-top: 8%;
  }

  .about-left p {
    font-weight: 650;
    font-size: 12px;
  }

  .about-row {
    display: flex;
  }

  .about-right {
    padding: 0;
    width: 60%;
  }

  .about-left {
    width: 40%;
  }

  .why-section {
    display: flex;
  }

  .why-left {
    width: 35%;
  }

  .why-right {
    width: 65%;
    padding: 0%;
  }

  .whybi h2 {
    font-size: 1.8rem;
  }

  .why-right p {
    font-size: 12px;
    padding: 0;
  }

  .up {
    display: flex;
  }

  .one {
    width: 50%;
    margin-bottom: 5%;
  }

  .whybi {
    height: auto;
  }

  .why-card img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .one .why-card {
    margin: 2px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    padding-top: 18px;
    padding-bottom: 0;
    min-height: 126px;
    background: rgba(187, 187, 201, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }

  .why-left img {
    padding-right: 8%;
    margin-top: 24%;
  }

  .why-left .bg-linear {
    height: 79%;
  }

  .why-left {
    padding: 0;
  }

  .services h2 {
    font-size: 1.8rem;
  }

  .services .serv-p {
    font-size: 12px;
    font-weight: 650;
  }

  .serv {
    min-height: 326px;
    margin: 10% auto;
    padding: 0 10%;
  }

  .serv-bgcard {
    min-height: 326px;
  }

  .serv-card {
    min-height: 326px;
  }

  .serv-title {
    font-size: 13px;
  }

  .serv-desc {
    font-size: 12px;
    margin: 0;
  }

  .techstack {
    height: auto;
  }

  .slick-dots {
    bottom: 5%;
  }

  .form-top h3 {
    font-size: 1.4rem;
  }

  .form-top p {
    font-size: 12px;
  }

  .form :placeholder-shown {
    font-size: 12px;
  }

  .form input {
    font-size: 12px;
    padding-left: 2%;
  }

  .footer .logoo img {
    height: 30px;
  }

  .logoo p {
    font-size: 12px;
  }

  .middle h3 {
    font-size: 12px;
  }

  .middle a {
    font-size: 12px;
  }

  .last h3 {
    font-size: 1rem;
  }

  .social-media h3 {
    font-size: 1rem;
  }

  .email p {
    font-size: 10px;
  }

  .whatis .why-left img {
    margin-top: 0%;
  }

  .serv-card-content .serv-card-content-title {
    padding-top: 5%;
    font-size: 1.1rem;
  }

  .what-left img {
    width: 80%;
    object-fit: cover;
    padding: 0;
    margin: auto;
  }

  .what-left {
    margin: auto 0;
  }

  .what-right {
    padding-left: 0;
  }

  .h2-md {
    font-size: 1.8rem !important;
  }

  .p-md {
    font-size: 12px !important;
  }

  .whybi-right {
    margin: auto 0;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    padding: 0 !important;
    margin: 0 !important;
  }

  .card-time {
    padding: 2%;
  }

  .key-feature-cex-card-desc {
    font-size: 12px;
  }

  .key-feature-cex-card-title {
    font-size: 1rem;
  }

  .medium-device-justify {
    justify-content: center;
  }

  .key-feature-cex-row1:hover {
    padding-bottom: 30%;
  }

  .bottom-padding-classs:hover {
    padding-top: 29%;
    padding-bottom: 0;
  }

  .wallet-card-title {
    font-size: 1.2rem;
    font-weight: 750;
  }

  .benefits i {
    font-size: 25px !important;
  }

  .vertical-timeline-element-content h5 {
    font-size: 1.62rem !important;
  }

  .vertical-timeline-element-icon svg {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
    filter: invert(1);
  }

  .key-feature-cex-card {
    min-height: 240px;
  }

  .key-feature-cex-card-desc {
    margin: 0;
  }
}

@media only screen and (max-width: 700px) {
  .proeven::before {
    display: none;
    margin-bottom: 10px;
  }

  .smartnetwork {
    width: 50% !important;
  }

  .token_head .smartSecure {
    width: 25% !important;
  }

  .token_head .whysmartcontract img {
    width: 12% !important;
    margin: 0 auto !important;
    display: block;
  }

  .token_head .whysmartcontract h3 {
    text-align: center !important;
  }

  .token_head .signpen {
    width: 30px !important;
  }

  .p2p.tokens {
    padding: 0px !important;
  }

  .proodd::before {
    background-image: none;
    margin-top: 10px;
    display: none;
  }

  .grid-container-fluid {
    display: flex;
    flex-wrap: wrap;

    gap: 20px;
  }

  .p2p-banner {
  }

  .keyfeature {
    margin: 10px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .p2p .h1tag,
  .cryptoex .h1tag {
    font-size: 32px;
    color: #fff;
    font-weight: 800;
    text-align: center;
  }

  .p2p p,
  .cryptoex_head p {
    font-size: 16px;
    margin-top: 10px;
  }

  .whatp2p h2,
  .gamesfeature h2,
  .gamesbenefits h2,
  .whatsmartcontract h2,
  .cryptoex_head h2,
  .cryptoex_head h3 {
    font-size: 28px;
  }

  .whatp2p p,
  .gamesfeature p,
  .gamesbenefits p,
  .whatsmartcontract p {
    font-size: 16px;
  }

  .firstp2p h2,
  .firstgames h2 {
    font-size: 28px;
    line-height: 34px;
    margin-top: 20px;
  }

  .p2p-process h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .pblock {
    font-size: 16px;
  }

  .p2p-keyfeatures h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .firstrow {
    margin-bottom: 25px;
  }

  .features-p2p h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .Benfit-p2p h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .how-p2p h2 {
    font-size: 28px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }

  .displayhiden {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .whatdefi h2,
  .nftbenfit h2,
  .usecases h2,
  .sec5dex h2,
  .sec4dex h2,
  .sec3dex h2,
  .sec2dex h2,
  .p2p-keyfeatures h2,
  .Benfit-p2p h2,
  .features-p2p h2,
  .whatp2p h2,
  .cryptoex_head h2,
  .whatsmartcontract h2,
  .gamesfeature h2,
  .gamesbenefits h2,
  .firstp2p h2,
  .firstgames h2,
  .process h3,
  .faq-content h3,
  .Overview h2,
  .nft .h1tag,
  .usecases h2,
  .devservices h2,
  .difheading h2.dif,
  .benfits-defi h2,
  .Finance-Development h2,
  .sectionss h2,
  .features-defi h2,
  .cryptoex_head h3 {
    font-size: 2rem !important;
    text-align: center !important;
  }

  .dex2 {
    padding: 20px !important;
  }

  .p2p .h1tag,
  .dex .h1tag,
  .cryptoex .h1tag {
    font-size: 2.4rem !important;
    margin-top: 25% !important;
    text-align: center !important;
    margin-bottom: 10px !important;
  }

  .welcome {
    font-size: 2.4rem !important;
    margin-top: 10% !important;
    text-align: center !important;
    margin-bottom: 10px !important;
  }

  .Defi p,
  .p2p p,
  .cryptoex_head p,
  .Overview p,
  .dex p,
  .dex2 p,
  .sec3dex p,
  .Marketplace p,
  .firstp2p p,
  .firstgames p,
  .whatp2p p,
  .whatsmartcontract p,
  .gamesfeature p,
  .gamesbenefits p,
  .nft p {
    font-size: 1.2rem !important;
    text-align: center !important;
  }

  .whatdefi p {
    font-size: 1rem !important;
    text-align: center !important;
  }

  .deximg,
  .sec3deximg {
    width: 50% !important;
  }

  .benfit-card-dex {
    margin: 1opx 0px !important;
  }

  .process-card-para {
    min-height: 300px !important;
    /* max-width: 340px !important; */
    margin: 0px 20% !important;
  }

  .round-image {
    display: none !important;
  }

  .Marketplace,
  .sec3dex,
  .how-p2p,
  .sec4dex,
  .process,
  .whatp2p,
  .whatsmartcontract,
  .gamesfeature,
  .gamesbenefits {
    padding: 10px 0px !important;
  }

  .key-feature-cex-card img {
    height: 60px;
    width: fit-content;
    padding-bottom: 5px;
    transition: all 0.3s ease-in-out;
  }

  .defi-banner {
    width: 60% !important;
  }

  .p2pimg,
  .what-left img,
  .whydeximg,
  .dexdevelopimg {
    width: 50% !important;
    display: block !important;
    margin: 0px auto !important;
  }

  .vsdeximg {
    width: 25% !important;
  }

  .overimg {
    width: 60% !important;
    margin-top: 20% !important;
  }

  .cex-cry-serv-card {
    margin-bottom: 10px !important;
  }

  .whatimg {
    width: 70% !important;
  }

  .usecases h2 {
    font-size: 28px;
  }

  .devservices h2 {
    font-size: 28px;
  }

  .difheading h2.dif {
    font-size: 20px;
  }

  .benfits-defi h2 {
    font-size: 28px;
  }

  .Finance-Development h2 {
    font-size: 24px;
  }

  .sectionss h2 {
    font-size: 28px;
  }

  .align-items-end {
    align-items: center !important;
    padding-bottom: 20px;
  }

  .align-items-start {
    align-items: center !important;
    padding-top: 20px;
  }

  .feature-card {
    margin-bottom: 20px;
  }

  .features-defi h2 {
    font-size: 28px;
  }

  .-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: none !important;
    animation: none !important;
  }

  .diftable td {
    font-size: 14px;
  }

  .nft .h1tag {
    font-size: 30px;
    color: #fff;
  }

  .nft p {
    font-size: 16px;
  }

  .btn.nftbtn {
    padding: 10px 25px !important;
    align-items: center;
  }

  .nft-banner {
    width: 100%;
    transform: scaleX(-1);
  }

  .Overview h2,
  .Marketplace h2 {
    font-size: 30px;
  }

  .think {
    width: 40% !important;
  }

  .Overview h2 {
    font-size: 30px;
    padding-top: 20px;
  }

  .process h3 {
    font-size: 30px;
  }

  .faqimg {
    width: 40% !important;
  }
}

@media only screen and (max-width: 767px) {
  .p2p.tokens img {
    width: 50%;
  }

  .token_head img {
    width: 50% !important;
  }

  .Benpayment img{
    width: 20% !important;
  }

  .token_head .processmartimg {
    width: 100% !important;
  }

  .benfit-img-p2p2 img {
    width: 100% !important;
  }

  .feature-card-tokens img {
    width: 70px !important;
    filter: brightness(0) !important;
  }

  .whatoke img {
    margin-top: 90px !important;
  }

  /* .navbar-light .navbar-toggler-icon {
      filter: invert(1);
    }
    .navbar.scrolled .navbar-light .navbar-toggler-icon {
      filter: brightness(100);
    } */
  .hero {
    height: 100vh;
  }

  .home-new .slick-track {
    width: 780px;
  }

  .home-new .slide1 {
    width: 100%;
  }

  .home-new .slide2 {
    width: 100%;
  }

  .hero-content {
    position: absolute;
    z-index: 4;
    padding: 25% 6%;
  }

  .hero-content .button {
    color: #fff;
    font-size: 13px;
    letter-spacing: 2px;
    background-color: rgba(11, 48, 75, 0.363);
    font-weight: 700;
    border: 1px solid white;
    border-radius: 5px;
    height: 40px;
    width: 170px;
    display: grid;
    margin: 0 auto;
    place-content: center;
    text-decoration: none;
    padding: 15px;
    -webkit-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
    -moz-box-shadow: 0px 0px 10px 1px rgb(0 149 255);
    box-shadow: 0px 0px 10px 1px rgb(0 149 255);
  }

  .slide1 .hero-content {
    width: 100%;
  }

  .slide2 .hero-content {
    width: 100%;
  }

  .slide1 {
    background-position: center;
  }

  .slide2 {
    background-position: 84%;
  }

  .p2p,
  .dex {
    background-position: 50%;
    background-color: #000 !important;
    opacity: 20 !important;
    z-index: 2 !important;
  }

  .hero-front {
    background-color: rgba(0, 0, 0, 0.815);
  }

  .hero-content .h1tag {
    font-size: 2rem;
    color: #fff;
    font-weight: 900;
    text-align: center;
  }

  .hero-title1 {
    margin-top: 0;
  }

  .hero-content p {
    font-size: 14px;
    color: #fff;
    line-height: 1.75;
    width: 100%;
    text-align: center;
  }

  .hero-content button,
  .Defi .button,
  .nft .button,
  .p2p .button,
  .dex .button,
  .cryptoex .button {
    display: block;
    margin: auto;
  }

  .slick-dots {
    bottom: 5%;
  }

  .aboutus {
    height: auto;
  }

  .about h2 {
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    color: #000;
    padding-top: 8%;
  }

  .about-left p {
    padding: 1% 8%;
    font-weight: 650;
    text-align: center;
  }

  .about-right {
    /* padding-left: 8%; */
    padding-right: 14%;
  }

  .about-img {
    height: auto;
  }

  .whybi {
    padding: 3% 0%;
    width: 100%;
    height: auto;
    background: #3d337c;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .why-left img {
    width: 100%;
    object-fit: cover;
    padding: 14%;
    padding-bottom: 0;
    margin-top: 10%;
  }

  .why-left .bg-linear {
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 84.98%,
      #3d337c 97%
    );
    width: 86%;
    height: 100%;
    z-index: 1;
    position: absolute;
    margin-top: 2px;
    margin-bottom: -3%;
  }

  .whybi h2 {
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
  }

  .why-right hr {
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .why-right p {
    text-align: center;
    padding: 2% 4%;
  }

  .why-right {
    /* padding: 10%; */
  }

  .one {
    margin-bottom: 5%;
  }

  .services h2 {
    font-size: 2rem;
    padding-bottom: 1%;
  }

  .techstack {
    height: auto;
  }

  .tech-h2 {
    font-size: 2rem;
  }

  .form-container {
    padding: 10%;
  }

  .form-card .form-img {
    overflow: hidden;
  }

  .form-top {
    padding: 11%;
    text-align: center;
  }

  .footer .middle h3 {
    width: 28%;
    padding: 0;
    margin: 0;
  }

  .footer .first {
    padding: 8% 8% 0% 8%;
  }

  .footr {
    padding: 0;
  }

  .middle {
    padding: 2% 8% 0% 8%;
  }

  .last {
    padding: 8%;
  }

  .social-media {
    padding: 0 8%;
  }

  .social-media h3 {
    margin-top: 0;
  }

  .social-media1 {
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }

  .footer {
    height: auto;
    padding: 0;
  }

  .copyrights {
    height: auto;
  }

  .copyrights h4 {
    font-size: 14px;
    margin: 5% 10%;
  }

  .cex-linear {
    background: #00000093;
  }

  .centralized-cex {
    background-position: right;
  }

  .whatis {
    background: #030303;
  }

  .whats .padding-class {
    padding-top: 0%;
  }

  .what h2 {
    font-size: 2rem;
    text-align: center;
    padding: 8% 0;
  }

  .what-right {
    padding-left: 10%;
  }

  .what p {
    font-size: 14px;
    text-align: center;
  }

  .what {
    /* padding: 15% 6%; */
  }

  .cex-cry-service {
    padding: 15% 6%;
  }

  .h2-mobile {
    font-size: 1.4rem !important;
    text-align: center;
    padding: 8% 0;
  }

  .p-mobile {
    font-size: 14px !important;
    text-align: center;
  }

  .cex-cry-serv-card {
    margin-bottom: 10%;
    height: 250px;
  }

  .serv-card-content {
    padding: 10px 20px;
  }

  .serv-card-content .serv-card-content-title {
    font-size: 1.2rem;
  }

  .serv-card-content .serv-card-content-desc {
    font-size: 12px;
    font-weight: 400;
  }

  .work-flow {
    padding: 5%;
  }

  .card-time {
    padding: 5%;
  }

  .vertical-timeline-element-date {
    opacity: 0;
    padding: 0 !important;
    margin: 0 !important;
  }

  .vertical-timeline-element-subtitle {
    font-size: 14px;
  }

  .vertical-timeline-element-content h5 {
    font-size: 1.32rem !important;
  }

  .vertical-timeline-element-content h3 {
    font-size: 3rem !important;
    font-weight: 900;
  }

  .why-bin {
    padding: 5%;
  }

  .down {
    justify-content: center;
  }

  .key-feature-cex-content p {
    width: 100%;
  }

  .key-feature-cex-card-title {
    font-size: 1.2rem;
  }

  .key-feature-cex-card {
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cex-card-img {
    min-width: 150px;
  }

  .key-feature-cex-row1 {
    display: flex;
    flex-direction: column;
  }

  .key-feature-cex-row1 .cex-card-img {
    min-width: 200px;
    min-height: 200px;
    margin-bottom: 10%;
    position: static;
  }

  .key-feature-cex-row1 {
    padding: 0;
  }

  .key-feature-cex-row1:hover {
    padding-bottom: 0%;
  }

  .bottom-padding-classs:hover {
    padding-top: 0%;
  }

  .key-feature-cex-row1:hover .cex-card-img {
    transform: rotate(0deg);
    top: 0;
    left: 0;
    right: 0;
  }

  .wallet-card {
    width: 300px;

    margin-bottom: 10%;
    margin: 10% auto 0;
  }

  .wallet-card-content {
    width: 300px;
  }

  .feature-section-3 {
    padding-bottom: 20%;
  }

  .benefits-cex-sec {
    padding: 7%;
  }

  .benefits-cex .benefits p {
    font-size: 14px;
  }

  .benefits-cex {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .benefits {
    justify-content: flex-start;
  }

  .faq {
    height: auto !important;
    min-height: auto;
  }

  .faq-content h3 {
    padding: 0;
  }

  .why-tick-card {
    align-items: center;
    justify-content: flex-start;
  }

  .why-mobile-center {
    padding-left: 17%;
  }

  .down {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .serv-card-content {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cex-cry-serv-card-row {
    padding-top: 0;
    padding-bottom: 0;
  }

  .serv-card-content-desc {
    opacity: 1 !important;
  }

  .title-down {
    width: 100%;
  }

  .what-right {
    padding-left: 15px;
  }

  .vertical-timeline--two-columns .vertical-timeline-element-content {
    min-height: 437px;
    min-width: 261px;
  }

  .accordion {
    width: 87%;
  }

  .faq {
    padding: 0% 0% 5%;
  }

  .faq-content p {
    padding-top: 6%;
  }

  .vertical-timeline-element-icon svg {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
    filter: invert(1);
  }

  .nft-banner1 {
    left: 35% !important;
    width: 45% !important;
  }

  .nft-banner2 {
    display: none;
  }

  .nft-banner3 {
    opacity: 0;
  }
}

@media only screen and (max-width: 650px) {
  .sixnone {
    display: none !important;
  }

  .nft-banner1 {
    left: 35% !important;
    width: 45% !important;
  }

  .nft-banner2 {
    display: none;
  }

  .nft-banner3 {
    opacity: 0;
  }
}

@media only screen and (max-width: 500px) {
  .footer_menyu_sss {
    display: none !important;
  }

  .process-card-para {
    min-width: 275px !important;
  }

  .token-process img {
    width: 25% !important;
  }

  .p2p-process,
  .sec3dex,
  .sec5dex,
  .sec4dex,
  .p2p-keyfeatures,
  .dex2 {
    padding: 10px 0px !important;
  }

  .sec2dex h2,
  .how-p2p h2,
  .sec3dex h2,
  .sec6dex h3,
  .benfit-card-dextitle,
  .sec5dex h2,
  .sec3dex h2 {
    font-size: 1.4rem !important;
    text-align: center !important;
  }

  .benfit-img-p2p2 {
    padding: 10px 0px !important;
    width: 50px !important;
    margin: 0px auto !important;
  }

  .benfit-card-dex {
    margin: 10px 0px !important;
  }

  .Benfit-card-p2psecond {
    background-color: #6f21a2;
    padding: 20px 25px 20px 25px;
    border-radius: 50px;
    margin: 20px 0;
    min-height: 222px !important;
  }

  .Benfit-card-tokensecond {
    background-color: #ffffff;
    padding: 20px 25px 20px 25px;
    border-radius: 50px;
    margin: 20px 0;
    min-height: 222px !important;
  }

  .benfeature-card-p2ptit {
    font-size: 15px !important;
    text-align: center !important;
  }

  .benfeature-card-p,
  .dexbenfit-card-para,
  .p2pcontent,
  .whatp2p p,
  .whatsmartcontract p,
  .gamesfeature p,
  .gamesbenefits p {
    font-size: 13px !important;
    text-align: center !important;
    margin: 0px !important;
  }

  .round-image {
    display: none !important;
  }

  .welcome,
  .nft .h1tag {
    margin-top: 20%;
    font-size: 1.4rem !important;
    text-align: center;
    color: #fff;
    font-weight: 900 !;
    width: 95%;
  }

  .Overview h2,
  .sec4dex h2,
  .Marketplace h2,
  .p2p-keyfeatures h2,
  .Benfit-p2p h2,
  .features-p2p h2,
  .firstp2p h2,
  .firstgames h2,
  .p2p-process h2,
  .whatp2p h2,
  .whatsmartcontract h2,
  .gamesfeature h2,
  .gamesbenefits h2,
  .process h3,
  .nft-usecase h3,
  .nftbenfit h2,
  .cex-cry-serv-content h2,
  .cryptoex_head h2,
  .cryptoex_head h3 {
    margin-top: 5%;
    font-size: 1.4rem !important;
    text-align: center;
    color: #fff;
    font-weight: 900 !important;
    width: 95%;
  }

  .p2p-keyfeatures h2 {
    margin-top: 5%;
    font-size: 1.4rem !important;
    text-align: center;
    color: #000;
    font-weight: 900 !important;
    width: 95%;
  }

  .title2 {
    font-size: 1.4rem !important;
    font-weight: 900 !important;
    text-align: center;
    padding: 10px 0px !important;
    margin: 0px !important;
  }

  .how-content {
    font-size: 13px !important;
    padding: 10px 0px !important;
    margin: 0px !important;
  }

  .p2p-process p {
    font-size: 13px !important;
    width: 100%;
    text-align: center;
    color: #fff;
    line-height: 1.75;
    margin-bottom: 3%;
    padding: 0 30px !important;
  }

  .nft-banner1 {
    left: 28% !important;
    width: 40% !important;
  }

  .dex .h1tag,
  .p2p .h1tag,
  .cryptoex .h1tag {
    margin-top: 40% !important;
    font-size: 2rem !important;
    text-align: center !important;
    color: #fff;
    font-weight: 900 !important;
  }

  .p2pcol {
    width: 100%;
    display: block !important;
  }

  .dexbtn,
  .p2pbtn {
    display: block;
    margin: 0px auto !important;
    padding: 10px 8% !important;
  }

  .Overview p,
  .dex p,
  .sec3dex p,
  .Defi p,
  .sec2dex p,
  .dexfeature-card-para,
  .dif-card-para,
  .dif-card-para1,
  .Marketplace p,
  .firstgames p,
  .p2p-keyfeatures p,
  .firstp2p p,
  .p2p p,
  .cryptoex_head p,
  .text-muted {
    font-size: 13px !important;
    width: 95%;
    text-align: center !important;
    color: #fff;
    line-height: 1.75;
    margin-bottom: 5%;
    padding: 0 !important;
  }

  .token-keyfeatures {
    font-size: 13px !important;
    width: 95%;
    text-align: center !important;
    color: #000;
    line-height: 1.75;
    margin-bottom: 5%;
    padding: 0 !important;
  }

  .timeline-item .text-muted {
    font-size: 13px !important;
    width: 95%;
    text-align: left !important;
    color: #fff;
    line-height: 1.75;
    margin-bottom: 5%;
    padding: 0 !important;
  }

  .whatdefi p {
    font-size: 13px !important;
    width: 100%;
    text-align: center !important;
    color: #000;
    line-height: 1.75;
    margin-bottom: 5%;
    padding: 0 !important;
  }

  .how-p2p p {
    font-size: 13px !important;
    width: 100%;
    text-align: center;
    color: #6f21a2;
    line-height: 1.75;
    margin-bottom: 3%;
    padding: 0 !important;
  }

  .list,
  .timeline-content h5 {
    font-size: 15px !important;
  }

  .nft-process-img {
    width: 40px !important;
    height: auto;
    background-color: #0c0e12;
    border-radius: 50%;
    margin-right: 20px;
    padding: 0 !important;
  }

  .Marketplace,
  .process,
  .benfits-defi,
  .nftbenfit,
  .nft-usecase,
  .how-p2p,
  .Benfit-p2p,
  .features-p2p,
  .whatp2p,
  .whatsmartcontract,
  .gamesfeature p,
  .gamesbenefits p,
  .firstp2p,
  .firstgames .whatp,
  .Overview {
    padding: 10px 0px !important;
  }

  .hero-content .h1tag {
    margin-top: 30%;
    font-size: 1.4rem !important;
    /* Adjust the font size as needed */
    color: #fff;
    font-weight: 900 !;
  }

  .nft p {
    font-size: 13px !important;
    width: 95%;
    text-align: center;
    color: #fff;
    line-height: 1.75;
    margin-bottom: 3%;
  }

  .hero-content p {
    font-size: 13px !important;
    width: 100%;
    color: #fff;
    line-height: 1.75;
    margin-bottom: 3%;
  }

  .about h2,
  .whybi h2,
  .services h2,
  .tech-h2 {
    font-size: 1.8rem !important;
    font-weight: 900 !important;
    text-align: center;
    padding-top: 8%;
  }

  .what-left img {
    width: 80%;
    margin: auto;
  }

  .what h2 {
    font-size: 1.4rem !important;
  }

  .about-left p,
  .why-right p,
  .services .serv-p,
  .stack p {
    font-weight: 650;
    text-align: center;
    font-size: 13px !important;
  }

  .whybi h2 {
    font-size: 1.8rem !important;
    font-weight: 900;
    text-align: center;
  }

  .services h2 {
    font-size: 1.8rem !important;
    font-weight: 900;
    text-align: center;
  }

  .serv-desc {
    font-size: 12px !important;
  }

  .btn.nftbtn {
    margin-left: 18%;
  }

  .nft-banner1 {
    left: 19% !important;
    width: 60% !important;
  }

  .nft-banner2 {
    display: none;
  }

  .nft-banner3 {
    opacity: 0;
  }

  .dex2 {
    padding: 10px 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .Defi p {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
  }

  /* 
    .btn.defibtn {
      padding: 6px 15px !important;
      font-size: 16px !important;
      margin-left: 40px;
  
    } */

  .defi-banner {
    width: 350px;
  }

  .btn.btn-secondary {
    padding: 6px 15px !important;
    font-size: 16px !important;
  }

  .diftable td {
    font-size: 12px;
  }
}

@media only screen and (max-width: 315px) {
  .btn.btn-secondary {
    padding: 6px 10px !important;
    font-size: 16px !important;
  }

  /* .btn.defibtn {
      padding: 6px 10px !important;
      font-size: 16px !important;
      margin-left: 10px;
  
    } */
}

.bi-list::before {
  content: "\f456";
}

@media only screen and (max-width: 991px) {
  .dropdown-menu,
  .dropdown.others .dropdown-menu,
  .dropdown.crypto .dropdown-menu {
    left: -7% !important;
  }

  .vsimage {
    display: none !important;
  }

  header {
    padding: 0px !important;
  }

  .res991 {
    display: block !important;
  }

  .proeven::before {
    display: none;
    margin-bottom: 10px;
  }

  .proodd::before {
    margin-top: 10px;
    display: none;
  }

  .grid-container-fluid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .token_head .whysmartcontract img {
    width: 12% !important;
    margin: 0 auto !important;
    display: block;
  }

  .token_head .whysmartcontract h3 {
    text-align: center !important;
  }

  .pro {
    font-size: 16px;
  }

  .keyfeature {
    margin: 10px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .process-card-para {
    min-height: 200px !important;
    min-width: 200px !important;
    max-height: 400px !important;
    max-width: 400px !important;
  }

  .processeven {
    flex-direction: column-reverse !important;
  }

  .deximg,
  .sec3deximg,
  .dexdevelopimg,
  .whydeximg,
  .overimg,
  .faqimg,
  .p2pimg,
  .firstp2pimg,
  .defi-banner,
  .whatimg {
    width: 60% !important;
    display: block !important;
    margin: 0px auto !important;
  }

  .vsdeximg {
    width: 20% !important;
  }

  .process-card-para {
    margin: 0px 10% !important;
    margin-bottom: 10% !important;
  }

  .proeven {
    margin-top: 0% !important;
  }

  .sec3dex h2,
  .p2pcontent h2,
  .p2pcontent p,
  .firstmeta h2,
  .firstmeta p,
  .sec3dex p,
  .firstokens h2,
  .firstokens p,
  .faq3 h3,
  .whatp2p p,
  .whatsmartcontract p,
  .gamesfeature p,
  .gamesbenefits p,
  .firstp2p h2,
  .nftbenfit h2,
  .firstp2p p,
  .how-content,
  .nft .h1tag,
  .nft p,
  .Overview h2,
  .Overview p,
  .Marketplace p {
    text-align: center !important;
  }

  .round-image {
    display: none;
  }

  .d1none {
    display: none;
  }

  .think {
    width: 35% !important;
  }

  .Defi .button,
  .nft .button {
    display: block;
    margin: 0px auto !important;
  }
}

@media only screen and (min-width: 991px) {
  .detailhidden {
    display: none !important;
  } 
}

@media only screen and (min-width: 991px) and (max-width: 1170px) {
  .nft-banner3 {
    width: 45%;
    left: 0;
  }

  .nft-banner2 {
    width: 45%;
    position: absolute;
    left: 62%;
  }

  .nft-banner1 {
    width: 35%;
    margin: 0 auto !important;
    position: absolute;
    top: -16%;
    left: 36%;
    z-index: 100000;
  }
}

@media only screen and (width: 768px) {
  .p2p,
  .nft {
    height: auto !important;
  }

  .p2p .banner_con,
  .nft .banner_con {
    margin: 10% 0% !important;
  }

  .nft .banner_i {
    margin-left: 30% !important;
  }

  .p2p .h1tag,
  .cryptoex .h1tag {
    margin: 10% 0% 0% 0% !important;
  }

  .p2p .button {
    margin-bottom: 10% !important;
  }
}

@media only screen and (width: 820px) {
  .p2p,
  .nft {
    height: auto !important;
  }

  .p2p .banner_con,
  .nft .banner_con {
    margin: 10% 0% !important;
  }

  .nft .banner_i {
    margin-left: 30% !important;
  }

  .p2p .h1tag {
    margin: 10% 0% 0% 0% !important;
  }

  .p2p .button {
    margin-bottom: 10% !important;
  }
}

@media only screen and (width: 1024px) {
  .p2p,
  .nft {
    height: auto !important;
  }

  .p2p .banner_con,
  .nft .banner_con {
    margin: 10% 0% !important;
  }

  .p2p .h1tag,
  .cryptoex .h1tag {
    margin: 10% 0% 0% 0% !important;
  }

  .p2p .button {
    margin-bottom: 10% !important;
  }
}

@media only screen and (width: 912px) {
  .p2p,
  .nft {
    height: auto !important;
  }

  .p2p .banner_con,
  .nft .banner_con {
    margin: 10% 0% !important;
  }

  .nft .banner_i {
    margin-left: 30% !important;
  }

  .p2p .h1tag {
    margin: 10% 0% 0% 0% !important;
  }

  .p2p .button {
    margin-bottom: 10% !important;
  }

  .hero .hero-title1{
    margin-top: 58% !important;
  }

}





@media only screen and (min-width: 991px) {
  /* .pposab{
    display: none !important;
  } */
  .nft {
    height: auto !important;
  }
}

@media only screen and (max-width: 963px) {
  .pposab{
    display: none !important;
  }
  .menu_new_typr {
    display: none !important;
  }
}

.logo-brand img {
  width: 40px !important;
}

.MuiAppBar-colorPrimary {
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(4px) !important;
  -webkit-backdrop-filter: blur(4px) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
}

.header_menu {
  border: 1px solid #053679 !important;
  border-radius: 10px;
  height: 115px;
  padding: 10px !important;
}

.feature-card-tokens img {
  width: 70px !important;
  filter: brightness(0) !important;
}

.feature-card-tokens p {
  color: #000 !important;
}

.text-black {
  color: #000 !important;
}

.dropdown-menu {
  display: none !important;
  border-radius: 25px !important;
}

.dropdown:hover .dropdown-menu {
  display: block !important;
}

.my-10 {
  margin: 6% 0px !important;
}
.my-6 {
  margin: 2% 0px !important;
}
.my-7 {
  margin: 5% 0px !important;
}

.posrelative {
  position: relative !important;
}
.posabsolute {
  position: absolute;
  top: 0;
  left: 35%;
}
.cryptofeature {
  background: #4b4b4b;
  border: none;
  border-radius: 15px;
}
.cryptofeature:hover {
  background: none !important;
  border: 1px solid #4b4b4b !important;
}

@media only screen and (max-width: 550px) {
  .posabsolute {
    width: 20% !important;
    left: 40%;
  }
}
.Benpayment p, .ATMcard p{
  text-align: left !important;
}

@media only screen and (width: 414px) {
  .hero-content .h1tag{
    margin-top: 60% !important;
  }
}


